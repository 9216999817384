import { useMemo } from 'react';
import PropTypes from 'prop-types';

import GroupApplicationCoverLetterItem from 'src/pages/dashboard/management/group/application/cover-letter-item';

import RootCard from 'src/components/root-card/root-card';

// ----------------------------------------------------------------------

export default function GroupApplicationCoverLetters({
  expandedItem,
  handleChange,
  groupId,
  user,
  postings,
  applicationCoverLetters,
}) {
  const sorted = useMemo(
    () =>
      (applicationCoverLetters || []).sort((a, b) => {
        if (a.createdAt < b.createdAt) {
          return 1;
        }
        if (a.createdAt > b.createdAt) {
          return -1;
        }
        return 0;
      }),
    [applicationCoverLetters]
  );

  if (!sorted.length) {
    return null;
  }

  return (
    <RootCard sx={{ p: 0 }}>
      {sorted.map((item, index) => (
        <GroupApplicationCoverLetterItem
          index={index}
          key={item._id}
          item={item}
          user={user}
          groupId={groupId}
          letterCount={applicationCoverLetters.length}
          isExpanded={expandedItem === item._id}
          expand={handleChange(item._id)}
          posting={postings.find((posting) => posting.projectId === item.projectId)}
        />
      ))}
    </RootCard>
  );
}

GroupApplicationCoverLetters.propTypes = {
  expandedItem: PropTypes.string,
  handleChange: PropTypes.func,
  groupId: PropTypes.string,
  user: PropTypes.object,
  postings: PropTypes.arrayOf(
    PropTypes.shape({
      projectId: PropTypes.string,
      postedAt: PropTypes.string,
      platform: PropTypes.string,
      sourceLink: PropTypes.string,
      status: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      details: PropTypes.shape({
        descriptionLanguage: PropTypes.string,
        authorCompany: PropTypes.string,
        authorName: PropTypes.string,
        hourlyRate: PropTypes.number,
        startDate: PropTypes.string,
        workload: PropTypes.string,
        durationInMonths: PropTypes.number,
        industry: PropTypes.string,
        contractType: PropTypes.string,
        workplace: PropTypes.string,
        location: PropTypes.string,
      }),
    })
  ),
  applicationCoverLetters: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      projectId: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      createdAt: PropTypes.string,
    })
  ),
};
