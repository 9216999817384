// FeatureFlagsIconButton.js
import React, { useState } from 'react';

import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';

import { useFeatureFlags } from 'src/hooks/use-feature-flags';

import Iconify from 'src/components/iconify';
import FeatureFlagsDialog from 'src/components/feature-flags/dialog';

const FeatureFlagsIconButton = () => {
  const { featureFlags } = useFeatureFlags();
  const [dialogOpen, setDialogOpen] = useState(false);

  // Button nur rendern, wenn mindestens ein Flag aktiv ist
  if (featureFlags.length === 0) {
    return null;
  }

  return (
    <>
      <IconButton
        onClick={() => setDialogOpen(true)}
        style={{ position: 'fixed', bottom: 16, left: 16 }}
        size="large"
      >
        <Badge badgeContent={featureFlags.length} color="error">
          <Iconify icon="solar:settings-bold" width={16} height={16} />
        </Badge>
      </IconButton>
      <FeatureFlagsDialog open={dialogOpen} onClose={() => setDialogOpen(false)} />
    </>
  );
};

export default FeatureFlagsIconButton;
