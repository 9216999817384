import React from 'react';
import PropTypes from 'prop-types';

import { Card, useTheme } from '@mui/material';

export default function RootCard({ children, sx, ...other }) {
  const theme = useTheme();

  return (
    <Card
      sx={{
        borderRadius: '24px',
        border: `1px solid ${theme.palette.grey[200]}`,
        boxShadow: 'none',
        p: 4,
        ...sx,
      }}
      {...other}
    >
      {children}
    </Card>
  );
}

RootCard.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
};
