import { useMemo } from 'react';
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import { Button, useTheme, Typography } from '@mui/material';

import matchLabel from 'src/utils/match-label';

import Iconify from 'src/components/iconify';
import { getMatchLabelColor } from 'src/components/match-level/match-level';

const getOpinionButtonStyles = (theme) => ({
  0: {
    active: {
      background: theme.palette.error[600],
      hover: theme.palette.error[700],
      color: theme.palette.common.white,
    },
    inactive: {
      background: theme.palette.error[200],
      hover: theme.palette.error[300],
      color: theme.palette.error[700],
    },
  },
  33: {
    active: {
      background: theme.palette.warning[400],
      hover: theme.palette.warning[500],
      color: theme.palette.common.white,
    },
    inactive: {
      background: theme.palette.warning[200],
      hover: theme.palette.warning[300],
      color: theme.palette.warning[800],
    },
  },
  66: {
    active: {
      background: theme.palette.success[500],
      hover: theme.palette.success[600],
      color: theme.palette.common.white,
    },
    inactive: {
      background: theme.palette.success[200],
      hover: theme.palette.success[300],
      color: theme.palette.success[800],
    },
  },
  100: {
    active: {
      background: theme.palette.success[700],
      hover: theme.palette.success[800],
      color: theme.palette.common.white,
    },
    inactive: {
      background: theme.palette.success[400],
      hover: theme.palette.success[500],
      color: theme.palette.success[800],
    },
  },
});

// ----------------------------------------------------------------------

export default function GroupInfoUserOpinion({ item, shown, upsertOpinion }) {
  const theme = useTheme();

  const opinionLevel = item?.userDetails?.opinion?.level ?? undefined;
  const originalMatchLevel = item?.userDetails?.matching?.result?.level ?? undefined;
  const originalMatchColor = getMatchLabelColor(originalMatchLevel, theme);
  const originalMatchLabel = matchLabel(originalMatchLevel, false);

  const opinionStyles = useMemo(() => getOpinionButtonStyles(theme), [theme]);

  if (!shown) {
    return null;
  }

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{ pt: 2, pb: 2, px: 2, backgroundColor: theme.palette.grey[100] }}
    >
      {/* Robert thinks, this project is a ... */}
      {typeof originalMatchLevel !== 'undefined' && (
        <Typography variant="body2">
          Robert thinks this project is a{' '}
          <Typography
            color={originalMatchColor}
            component="span"
            variant="body2"
            sx={{ fontWeight: 'semiBold' }}
          >
            {originalMatchLabel}
          </Typography>{' '}
          for your profile. How would you rate it?
        </Typography>
      )}

      {/* No Robert match, so ask the user about his opinion */}
      {!originalMatchLevel && (
        <Typography variant="body2">
          How good do you think this project matches your profile?
        </Typography>
      )}

      {/* Buttons for all match levels */}
      <Stack direction="row" spacing={1} sx={{ width: 1, flexWrap: 'wrap' }}>
        {[0, 33, 66, 100].map((level) => (
          <Button
            key={level}
            data-level={level}
            variant={opinionLevel === level ? 'contained' : 'soft'}
            onClick={() => {
              upsertOpinion(level);
            }}
            sx={{
              color:
                opinionLevel === level
                  ? opinionStyles[level].active.color
                  : opinionStyles[level].inactive.color,
              backgroundColor:
                opinionLevel === level
                  ? opinionStyles[level].active.background
                  : opinionStyles[level].inactive.background,
              '&:hover': {
                backgroundColor:
                  opinionLevel === level
                    ? opinionStyles[level].active.hover
                    : opinionStyles[level].inactive.hover,
              },
            }}
            startIcon={opinionLevel === level ? <Iconify icon="mdi:check" /> : null}
          >
            {matchLabel(level, level > 0)}
          </Button>
        ))}
      </Stack>
    </Stack>
  );
}

GroupInfoUserOpinion.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string,
    createdAt: PropTypes.string,
    title: PropTypes.string,
    postingCount: PropTypes.number,
    postings: PropTypes.arrayOf(
      PropTypes.shape({
        projectId: PropTypes.string,
        postedAt: PropTypes.string,
        platform: PropTypes.string,
        sourceLink: PropTypes.string,
        status: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        details: PropTypes.shape({
          descriptionLanguage: PropTypes.string,
          authorCompany: PropTypes.string,
          authorName: PropTypes.string,
          hourlyRate: PropTypes.number,
          startDate: PropTypes.string,
          workload: PropTypes.string,
          durationInMonths: PropTypes.number,
          industry: PropTypes.string,
          contractType: PropTypes.string,
          workplace: PropTypes.string,
          location: PropTypes.string,
        }),
      })
    ),
    details: PropTypes.shape({
      hourlyRate: PropTypes.number,
      startDate: PropTypes.string,
      workload: PropTypes.string,
      durationInMonths: PropTypes.number,
      industry: PropTypes.string,
      contractType: PropTypes.string,
      workplace: PropTypes.string,
      location: PropTypes.string,
    }),
    skills: PropTypes.shape({
      labels: PropTypes.arrayOf(PropTypes.string),
      references: PropTypes.arrayOf(PropTypes.string),
    }),
    userDetails: PropTypes.shape({
      opinion: PropTypes.shape({
        projectId: PropTypes.string,
        comment: PropTypes.string,
        level: PropTypes.number,
        createdAt: PropTypes.string,
      }),
      matching: PropTypes.shape({
        projectId: PropTypes.string,
        createdAt: PropTypes.string,
        status: PropTypes.string,
        result: PropTypes.shape({
          information: PropTypes.string,
          level: PropTypes.number,
          missingSkills: PropTypes.arrayOf(PropTypes.string),
          missingSkillsReferences: PropTypes.arrayOf(PropTypes.string),
          personalAnalysis: PropTypes.string,
        }),
      }),
      applicationCoverLetters: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
          projectId: PropTypes.string,
          title: PropTypes.string,
          description: PropTypes.string,
          createdAt: PropTypes.string,
        })
      ),
    }),
  }),
  shown: PropTypes.bool,
  upsertOpinion: PropTypes.func,
};
