import PropTypes from 'prop-types';

import { Link, Stack, Tooltip, Typography } from '@mui/material';

import { RouterLink } from 'src/routes/components';

import GroupApplicationCreate from 'src/pages/dashboard/management/group/application/create';

import Image from 'src/components/image';
import RoboHeadline from 'src/components/robo-headline';
import RootCard from 'src/components/root-card/root-card';

// ----------------------------------------------------------------------

export default function GroupApplicationRoboIntroCv({
  handleChange,
  groupId,
  postings,
  user,
  showForm,
}) {
  return (
    <RootCard sx={{ p: 2 }}>
      <Stack direction="column" spacing={3}>
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
          <Link component={RouterLink} href="/robert">
            <Tooltip title="Hi, I'm Robert – nice to meet you!" placement="top" arrow>
              <Image src="/logo/color/logo.svg" sx={{ width: 64 }} />
            </Tooltip>
          </Link>
          <RoboHeadline variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
            Let&apos;s Get Started!
          </RoboHeadline>
        </Stack>

        <Typography variant="body2" sx={{ p: 0 }}>
          I see you&apos;ve uploaded your resume – great start! Click on the button below and
          I&apos;ll create a cover letter for this project application. For a more personal touch,
          you can upload up to five sample letters through the sidebar. This helps me understand
          your unique style and tone for even better results.
        </Typography>

        {showForm && (
          <GroupApplicationCreate
            handleChange={handleChange}
            groupId={groupId}
            postings={postings}
            user={user}
          />
        )}
      </Stack>
    </RootCard>
  );
}

GroupApplicationRoboIntroCv.propTypes = {
  handleChange: PropTypes.func,
  groupId: PropTypes.string,
  showForm: PropTypes.bool,
  user: PropTypes.object,
  postings: PropTypes.arrayOf(
    PropTypes.shape({
      projectId: PropTypes.string,
      postedAt: PropTypes.string,
      platform: PropTypes.string,
      sourceLink: PropTypes.string,
      status: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      details: PropTypes.shape({
        descriptionLanguage: PropTypes.string,
        authorCompany: PropTypes.string,
        authorName: PropTypes.string,
        hourlyRate: PropTypes.number,
        startDate: PropTypes.string,
        workload: PropTypes.string,
        durationInMonths: PropTypes.number,
        industry: PropTypes.string,
        contractType: PropTypes.string,
        workplace: PropTypes.string,
        location: PropTypes.string,
      }),
    })
  ),
};
