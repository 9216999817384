import PropTypes from 'prop-types';

import { Box, Stack, useTheme } from '@mui/material';

import { useScopes } from 'src/hooks/use-scopes';

import { AppLogViewer } from 'src/components/app-log-viewer/app-log-viewer';
import { ActionEventsViewer } from 'src/components/action-events-viewer/action-events-viewer';
import { DuplicationInfoViewer } from 'src/components/duplication-info-viewer/duplication-info-viewer';

export default function ActionBar({ userId, matchingId, projectId, projectGroupId, postingIds }) {
  const theme = useTheme();
  const { hasScope } = useScopes();

  if (!hasScope('admin:users')) {
    return null;
  }

  if (!userId && !matchingId && !projectId && !projectGroupId && !postingIds) {
    return null;
  }

  return (
    <Box
      sx={{
        p: 1,
        borderRadius: 1,
        backgroundColor: 'grey.200',
        boxShadow: theme.customShadows.dropdown,
        position: 'fixed',
        bottom: '24px',
        right: '24px',
        zIndex: 1100,
      }}
    >
      <Stack direction="row" spacing={1}>
        {userId && <ActionEventsViewer userId={userId} />}
        {userId && (
          <AppLogViewer
            dependencies={{
              userId,
              matchingId,
              projectId,
              projectGroupId,
            }}
          />
        )}
        {postingIds && (
          <DuplicationInfoViewer
            dependencies={{
              postingIds,
            }}
          />
        )}
      </Stack>
    </Box>
  );
}

ActionBar.propTypes = {
  userId: PropTypes.string,
  matchingId: PropTypes.string,
  projectId: PropTypes.string,
  projectGroupId: PropTypes.string,
  postingIds: PropTypes.arrayOf(PropTypes.string),
};
