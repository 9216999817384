import PropTypes from 'prop-types';

import { Box, Stack, Tooltip, useTheme, Typography, CircularProgress } from '@mui/material';

import { ABORT_REASONS } from 'src/utils/abort-reasons';
import matchLabel, { matchLabelSentence } from 'src/utils/match-label';

import Iconify from 'src/components/iconify';

export const matchThemeColor = {
  0: 'error',
  33: 'warning',
  66: 'success',
  100: 'success',
};

export function getMatchLevelColor(matchLevel, theme) {
  switch (true) {
    case typeof matchLevel === 'undefined':
      return theme.palette.grey[200];
    case matchLevel === 0:
      return theme.palette.error[600];
    case matchLevel <= 33:
      return theme.palette.warning[400];
    case matchLevel <= 66:
      return theme.palette.success[500];
    default:
      return theme.palette.success[700];
  }
}

export function getMatchLevelBgColor(matchLevel, theme) {
  switch (true) {
    case typeof matchLevel === 'undefined':
      return theme.palette.grey[200];
    default:
      return getMatchLevelColor(matchLevel, theme);
  }
}

export function getMatchLabelColor(matchLevel, theme) {
  switch (true) {
    case typeof matchLevel === 'undefined':
      return theme.palette.grey[700];
    case matchLevel === 0:
      return theme.palette.error[600];
    case matchLevel <= 33:
      return theme.palette.warning[400];
    case matchLevel <= 66:
      return theme.palette.success[500];
    default:
      return theme.palette.success.dark;
  }
}

export function MatchLevelView({
  tooltip,
  loading,
  percentage,
  color,
  label,
  icon,
  labelSx,
  labelVariant = 'caption',
  hideLabel = false,
  direction = 'column',
}) {
  const theme = useTheme();
  const iconColor = color || getMatchLevelColor(percentage, theme);
  const textColor = color || getMatchLabelColor(percentage, theme);

  const size = 20;
  const thickness = 5;

  const loadingCircles = (
    <CircularProgress
      value={undefined}
      variant="indeterminate"
      thickness={thickness / 2}
      size={size}
      sx={{ color: iconColor, opacity: 0.5 }}
    />
  );

  const realCircles = (
    <>
      {/* putting a opaque circle behind the real circle */}
      <CircularProgress
        value={100}
        variant="determinate"
        thickness={thickness}
        size={size}
        sx={{
          color: iconColor,
          opacity: 0.25,
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      />

      <CircularProgress
        value={percentage}
        variant="determinate"
        thickness={thickness}
        size={size}
        sx={{ color: iconColor }}
      />
    </>
  );

  let tooltipLabel = !loading ? matchLabelSentence(percentage) : undefined;

  if (tooltip) {
    tooltipLabel = tooltip;
  }

  const minWidth = direction === 'column' ? '75px' : 'none';

  return (
    <Tooltip title={tooltipLabel} placement="top" arrow>
      <Stack spacing={direction === 'column' ? 0.5 : 1} direction={direction} sx={{ zIndex: 100 }}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{ minWidth }}>
          <Box
            sx={{
              width: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                position: 'relative',
                width: `${size}px`,
                height: `${size}px`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {loading && loadingCircles}

              {!loading && realCircles}

              {icon && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {icon}
                </Box>
              )}
            </Box>
          </Box>
        </Stack>

        {hideLabel === false && (
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Typography
              variant={labelVariant}
              sx={{
                color: textColor,
                whiteSpace: 'nowrap',
                textAlign: 'center',
                lineHeight: 1,
                ...labelSx,
              }}
            >
              {label}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Tooltip>
  );
}

MatchLevelView.propTypes = {
  tooltip: PropTypes.string,
  loading: PropTypes.bool,
  percentage: PropTypes.number,
  label: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.node,
  labelSx: PropTypes.object,
  hideLabel: PropTypes.bool,
  labelVariant: PropTypes.string,
  direction: PropTypes.oneOf(['row', 'column']),
};

// ----------------------------------------------------------------------

export default function MatchLevel({
  status,
  level,
  robertLevel,
  opinion,
  direction,
  color,
  hideLabel,
  labelSx,
  labelVariant,
  abortType,
  abortReason,
  abortInfo,
  labelMode = 'short',
}) {
  const theme = useTheme();

  if (opinion) {
    return (
      <MatchLevelView
        color={color}
        labelSx={labelSx}
        labelVariant={labelVariant}
        hideLabel={hideLabel}
        direction={direction}
        tooltip={opinion.comment}
        percentage={opinion.level}
        label={matchLabel(opinion.level, labelMode === 'short')}
        icon={
          <Iconify
            width={16}
            height={16}
            icon="carbon:user-avatar-filled"
            color={robertLevel === opinion?.level ? theme.palette.primary.main : undefined}
          />
        }
      />
    );
  }

  if (!status) {
    return null;
  }

  if (status === 'INITIALIZED' || status === 'PROCESSING') {
    return (
      <MatchLevelView
        labelSx={labelSx}
        hideLabel={hideLabel}
        labelVariant={labelVariant}
        direction={direction}
        loading
        label="Loading"
        color={color || theme.palette.grey[500]}
      />
    );
  }

  if (status === 'ABORTED') {
    let viewColor = color;

    if (!viewColor) {
      viewColor = theme.palette.warning.main;
    }

    if (ABORT_REASONS[abortReason]?.color) {
      viewColor = ABORT_REASONS[abortReason].color(theme);
    }

    return (
      <MatchLevelView
        labelSx={labelSx}
        hideLabel={hideLabel}
        labelVariant={labelVariant}
        direction={direction}
        percentage={undefined}
        color={viewColor}
        label={ABORT_REASONS[abortReason]?.label || 'Unknown'}
        tooltip={ABORT_REASONS[abortReason]?.info?.(abortInfo) || undefined}
        icon={<Iconify width={16} icon="solar:info-circle-bold" />}
      />
    );
  }

  const percentage = parseInt(level, 10) || 0;

  return (
    <MatchLevelView
      color={color}
      labelSx={labelSx}
      labelVariant={labelVariant}
      hideLabel={hideLabel}
      direction={direction}
      percentage={percentage}
      label={matchLabel(level, labelMode === 'short')}
    />
  );
}

MatchLevel.propTypes = {
  labelSx: PropTypes.object,
  labelVariant: PropTypes.string,
  labelMode: PropTypes.oneOf(['short', 'long']),
  hideLabel: PropTypes.bool,
  status: PropTypes.string,
  level: PropTypes.number,
  robertLevel: PropTypes.number,
  opinion: PropTypes.object,
  color: PropTypes.string,
  direction: PropTypes.oneOf(['row', 'column']),
  abortType: PropTypes.string,
  abortReason: PropTypes.string,
  abortInfo: PropTypes.object,
};
