import { useContext } from 'react';

import { AuthContext } from 'src/components/auth/context/auth-context';

export function useAuthState() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth muss innerhalb eines <AuthProvider> verwendet werden.');
  }
  return context;
}
