import { Box, Tooltip } from '@mui/material';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function ArchivedIcon() {
  return (
    <Tooltip title="This project has already been archived on the platform" arrow placement="top">
      <Box
        sx={{
          width: 24,
          height: 24,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Iconify icon="carbon:archive" width={16} height={16} />
      </Box>
    </Tooltip>
  );
}

ArchivedIcon.propTypes = {};
