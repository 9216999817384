import { useTheme } from '@emotion/react';

import Box from '@mui/material/Box';

import { ENVIRONMENT } from 'src/config-global';

// ----------------------------------------------------------------------

export default function EnvironmentBanner() {
  const theme = useTheme();

  const colorMapping = {
    staging: theme.palette.warning.main,
    'local-dev': theme.palette.primary.main,
    'local-staging': theme.palette.warning.main,
    'local-prod': theme.palette.error.main,
  };

  if (!Object.keys(colorMapping).includes(ENVIRONMENT) || colorMapping[ENVIRONMENT] === undefined) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        zIndex: 9999,
        pointerEvents: 'none',
        border: `3px solid ${colorMapping[ENVIRONMENT]}`,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          padding: `${theme.spacing(0.5)} ${theme.spacing(5)}`,
          color: 'white',
          backgroundColor: colorMapping[ENVIRONMENT],
          fontSize: 12,
          fontWeight: 'semiBold',
          borderRadius: '0 0 8px 8px',
        }}
      >
        {ENVIRONMENT}
      </Box>
    </Box>
  );
}

EnvironmentBanner.propTypes = {};
