import React from 'react';
import PropTypes from 'prop-types';

import List from '@mui/material/List';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import ListItemText from '@mui/material/ListItemText';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useFeatureFlags } from 'src/hooks/use-feature-flags';

import Iconify from 'src/components/iconify';

const FeatureFlagsDialog = ({ open, onClose }) => {
  const { featureFlags, removeFlag } = useFeatureFlags();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Enabled feature flags</DialogTitle>
      <DialogContent>
        <List>
          {featureFlags.map((flag) => (
            <ListItem
              key={flag}
              secondaryAction={
                <IconButton edge="end" aria-label="delete" onClick={() => removeFlag(flag)}>
                  <Iconify icon="solar:trash-bin-trash-bold" width={16} height={16} />
                </IconButton>
              }
            >
              <ListItemText primary={flag} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

FeatureFlagsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default FeatureFlagsDialog;
