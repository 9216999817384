import { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import AuthUserAware from 'src/routes/wrapper/auth-user-aware';
import InternalUserAware from 'src/routes/wrapper/internal-user-aware';

import RootLayout from 'src/layouts/root';

import { dashboardRoutes } from './dashboard';

const LoginPage = lazy(() => import('src/pages/login'));
const RobertPage = lazy(() => import('src/pages/robert'));
const ImprintPage = lazy(() => import('src/pages/imprint'));
const PrivacyPage = lazy(() => import('src/pages/privacy'));
const TermsPage = lazy(() => import('src/pages/terms'));
const RegisterPage = lazy(() => import('src/pages/register'));
const EmailConfirm = lazy(() => import('src/pages/email-confirm'));
const ResetPasswordPage = lazy(() => import('src/pages/reset-password'));
const ForgotPasswordPage = lazy(() => import('src/pages/forgot-password'));
const ProjectGroupAnonPage = lazy(() => import('src/pages/project-group-anon'));
const Onboarding = lazy(() => import('src/sections/onboarding'));
const HomePage = lazy(() => import('src/pages/home'));
const LogoutPage = lazy(() => import('src/pages/logout'));
const Page500 = lazy(() => import('src/pages/500'));
const Page403 = lazy(() => import('src/pages/403'));
const Page404 = lazy(() => import('src/pages/404'));
const PageCheckoutSuccess = lazy(() => import('src/pages/callbacks/checkout-success'));
const PageCheckoutCanceled = lazy(() => import('src/pages/callbacks/checkout-canceled'));
const StylesPage = lazy(() => import('src/pages/styles'));

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // SET INDEX PAGE WITH SKIP HOME PAGE
    // {
    //   path: '/',
    //   element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    // },

    // ----------------------------------------------------------------------

    // SET INDEX PAGE WITH HOME PAGE
    {
      path: '/',
      element: (
        <RootLayout>
          <HomePage />
        </RootLayout>
      ),
    },
    {
      path: '/imprint',
      element: (
        <RootLayout>
          <ImprintPage />
        </RootLayout>
      ),
    },
    {
      path: '/privacy',
      element: (
        <RootLayout>
          <PrivacyPage />
        </RootLayout>
      ),
    },
    {
      path: '/terms',
      element: (
        <RootLayout>
          <TermsPage />
        </RootLayout>
      ),
    },
    {
      path: '/forgot-password',
      element: (
        <RootLayout>
          <ForgotPasswordPage />
        </RootLayout>
      ),
    },
    {
      path: '/reset-password',
      element: (
        <RootLayout>
          <ResetPasswordPage />
        </RootLayout>
      ),
    },
    {
      path: '/robert',
      element: (
        <RootLayout>
          <RobertPage />
        </RootLayout>
      ),
    },
    {
      path: '/register',
      element: (
        <RootLayout>
          <RegisterPage />
        </RootLayout>
      ),
    },
    {
      path: '/login',
      element: (
        <RootLayout>
          <LoginPage />
        </RootLayout>
      ),
    },
    {
      path: '/styles',
      element: (
        <RootLayout>
          <StylesPage />
        </RootLayout>
      ),
    },
    {
      path: '/email-confirm',
      element: (
        <RootLayout>
          <EmailConfirm />
        </RootLayout>
      ),
    },
    {
      path: '/onboarding',
      element: (
        <RootLayout>
          <AuthUserAware>
            <InternalUserAware>
              <Onboarding />
            </InternalUserAware>
          </AuthUserAware>
        </RootLayout>
      ),
    },
    {
      path: '/p/:slug/:groupId',
      element: (
        <RootLayout>
          <ProjectGroupAnonPage />
        </RootLayout>
      ),
    },
    {
      path: '/logout',
      element: (
        <RootLayout>
          <LogoutPage />
        </RootLayout>
      ),
    },
    {
      path: '/500',
      element: (
        <RootLayout>
          <Page500 />
        </RootLayout>
      ),
    },
    {
      path: '/404',
      element: (
        <RootLayout>
          <Page404 />
        </RootLayout>
      ),
    },
    {
      path: '/403',
      element: (
        <RootLayout>
          <Page403 />
        </RootLayout>
      ),
    },
    {
      path: '/checkout-success',
      element: (
        <RootLayout>
          <PageCheckoutSuccess />
        </RootLayout>
      ),
    },
    {
      path: '/checkout-canceled',
      element: (
        <RootLayout>
          <PageCheckoutCanceled />
        </RootLayout>
      ),
    },

    ...dashboardRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
