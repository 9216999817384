import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { List as ListIcon } from '@phosphor-icons/react';

import {
  Box,
  Link,
  Stack,
  AppBar,
  Button,
  useTheme,
  Collapse,
  Container,
  IconButton,
  useMediaQuery,
} from '@mui/material';

import { usePathname } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';

import { useAuthState } from 'src/hooks/use-auth-state';

import LoginButton from 'src/layouts/common/login-button';

// ---------------------------------------------
// 1) Deine Button-Komponente
// ---------------------------------------------
function NavbarButton({ children, active, target, onClick }) {
  const theme = useTheme();

  // Standard-Klickverhalten für Smooth-Scroll
  const handleClick = (event) => {
    event.preventDefault();

    const element = document.querySelector(target);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      // Optional: Hash in der URL aktualisieren
      window.history.pushState(null, '', target);
    }
    if (onClick) {
      onClick(); // Falls wir nach dem Klick das Drawer schließen wollen
    }
  };

  return (
    <Button
      LinkComponent={RouterLink}
      href={target}
      onClick={handleClick}
      sx={{
        flexShrink: 0,
        whiteSpace: 'nowrap',
        px: 1,
        fontSize: 14,
        textTransform: 'none',
        fontWeight: 600,
        color: active ? 'text.primary' : 'text.secondary',
        backgroundColor: active ? theme.palette.grey[50] : theme.palette.common.white,
        '&:hover': {
          backgroundColor: theme.palette.grey[50],
        },
      }}
    >
      {children}
    </Button>
  );
}

NavbarButton.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  target: PropTypes.string,
  onClick: PropTypes.func,
};

// ---------------------------------------------
// 2) Navigation-Konfiguration
// ---------------------------------------------
const START_NAVIGATION = [
  { title: 'Home', target: '#home' },
  { title: 'Your benefits', target: '#benefits' },
  { title: 'How it works', target: '#how-it-works' },
  { title: 'Pricing', target: '#pricing' },
];

// ---------------------------------------------
// 3) Header-Komponente
// ---------------------------------------------
export default function Header() {
  const backdropIntensity = 0.5;
  const theme = useTheme();
  const { authenticated } = useAuthState();
  const pathname = usePathname();

  // Ermittel, ob wir auf der Startseite sind
  // (Passe dies an dein Routing an, wenn du z. B. Next.js oder React Router verwendest)
  const isHomePage = pathname === '/';

  // State für die aktive Sektion (Scroll-Spy)
  const [activeSection, setActiveSection] = useState('home');
  // Steuerung, ob das mobile Menü ausgeklappt ist
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  // Medienabfrage: ab "md" sind wir "Desktop", darunter "Mobile"
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  // -------------------------------------------
  // A) IntersectionObserver (Scroll-Spy)
  // -------------------------------------------
  useEffect(() => {
    if (!isHomePage) {
      // Nur auf der Startseite das Tracking starten
      return () => {};
    }

    const sectionIds = START_NAVIGATION.map((navItem) => navItem.target.replace('#', ''));
    const sections = sectionIds.map((id) => document.getElementById(id)).filter(Boolean);

    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Ab 50% Sichtbarkeit
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    sections.forEach((section) => observer.observe(section));

    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
  }, [isHomePage]);

  // -------------------------------------------
  // B) Deep-Link Hash beim ersten Laden abfragen
  // -------------------------------------------
  useEffect(() => {
    if (!isHomePage) return; // Nur auf der Startseite anwenden

    const { hash } = window.location; // z.B. #benefits

    if (hash) {
      const targetElement = document.querySelector(hash);
      if (targetElement) {
        setTimeout(() => {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }, 100);
      }
    }
  }, [isHomePage]);

  // -------------------------------------------
  // C) Mobile-Menü togglen
  // -------------------------------------------
  const toggleMobileMenu = () => {
    setMobileMenuOpen((prev) => !prev);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  // Schließe das mobile Menü, wenn wir in den Desktop-Modus wechseln
  useEffect(() => {
    if (isDesktop) {
      setMobileMenuOpen(false);
    }
  }, [isDesktop]);

  // -------------------------------------------
  // D) Klick auf das Logo
  // -------------------------------------------
  const handleLogoClick = (e) => {
    // Wenn wir bereits auf der Startseite sind, nur nach oben scrollen
    if (isHomePage) {
      e.preventDefault();
      window.scrollTo({ top: 0, behavior: 'smooth' });
      // Optional: Hash entfernen
      window.history.pushState(null, '', '/');
    }
    // Sonst ganz normal auf die Startseite weiterleiten (RouterLink übernimmt das)
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        boxShadow: 'none', // kein Schatten
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 2,
          py: 1,
          mt: { xs: 0, lg: 2 },
          height: 52,

          borderRadius: { xs: 0, lg: '12px' },
          backdropFilter: 'blur(20px)',
          backgroundColor: `rgba(255, 255, 255, ${backdropIntensity})`,
        }}
      >
        {/* Logo links */}
        <Box sx={{ display: 'flex', alignItems: 'center', flexShrink: 0 }}>
          <Link component={RouterLink} href="/" onClick={handleLogoClick}>
            <img src="/logo/color/logo-with-title.svg" alt="projectfit.ai" style={{ height: 30 }} />
          </Link>
        </Box>

        {/* Desktop-Navigation nur, wenn wir auf der Startseite sind */}
        {isHomePage && isDesktop && (
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              flexWrap: 'wrap',
              overflow: 'hidden',
              maxHeight: '36px',
              flexGrow: 1,
              ml: 2,
            }}
          >
            {START_NAVIGATION.map(({ title, target }) => {
              const targetId = target.replace('#', '');
              return (
                <NavbarButton key={target} target={target} active={activeSection === targetId}>
                  {title}
                </NavbarButton>
              );
            })}
          </Box>
        )}

        {/* Rechts: Login + ggf. Notifications + Avatar, nur wenn auf Startseite */}
        {isHomePage && (
          <Stack direction="row" gap={2}>
            <LoginButton label={authenticated ? 'Dashboard' : 'Login'} sx={{ mr: 0 }} />

            {/* Mobile-Bereich: Burger-Icon, nur sichtbar, wenn wir wirklich mobile sind */}
            {!isDesktop && (
              <IconButton onClick={toggleMobileMenu} sx={{ color: 'text.primary' }}>
                <ListIcon />
              </IconButton>
            )}
          </Stack>
        )}
      </Container>

      {/* Mobile Drawer-Menü nur anzeigen, wenn auf Startseite */}
      {isHomePage && !isDesktop && (
        <Collapse in={mobileMenuOpen} unmountOnExit>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              px: 2,
              pb: 2,
              gap: 1,

              borderRadius: 0,
              backdropFilter: 'blur(20px)',
              backgroundColor: `rgba(255, 255, 255, ${backdropIntensity})`,
            }}
          >
            {START_NAVIGATION.map(({ title, target }) => {
              const targetId = target.replace('#', '');
              return (
                <NavbarButton
                  key={target}
                  target={target}
                  active={activeSection === targetId}
                  onClick={closeMobileMenu}
                >
                  {title}
                </NavbarButton>
              );
            })}
          </Box>
        </Collapse>
      )}
    </AppBar>
  );
}
