import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useRef, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { LoadingButton } from '@mui/lab';
import { Box, Stack, Divider, MenuItem, Typography } from '@mui/material';

import { useApi } from 'src/hooks/use-api';
import { useDocuments } from 'src/hooks/use-documents';
import { useProjectGroup } from 'src/hooks/use-project-group';
import { useSampleCoverLetters } from 'src/hooks/use-sample-cover-letters';

import GroupApplicationCreatePostingLabel from 'src/pages/dashboard/management/group/application/create-posting-label';

import FormProvider from 'src/components/hook-form/form-provider';
import { RHFSelect, RHFRadioGroup } from 'src/components/hook-form';

// ----------------------------------------------------------------------

const schema = Yup.object().shape({
  postingId: Yup.string().required('Please choose a posting'),
  cvId: Yup.string().required('Please choose a cv to use'),
  sampleCoverLetterIds: Yup.array(Yup.string()).min(0),
});

const cachePrefix = 'posting-application-cover-letter';

// ----------------------------------------------------------------------

export default function GroupApplicationCreate({ handleChange, groupId, postings, user }) {
  const api = useApi();
  const [searchParams] = useSearchParams();
  const initializedValues = useRef(false);
  const { enqueueSnackbar } = useSnackbar();

  /**
   * Data Fetching
   */
  const { mutate } = useProjectGroup(groupId, user._id);
  const { data: documents, isLoading: documentsLoading } = useDocuments(user._id);
  const { data: sampleCoverLetters, isLoading: lettersLoading } = useSampleCoverLetters(user?._id);

  let defaultPosting = searchParams.get('posting');

  if (postings.length === 1) {
    defaultPosting = postings[0].projectId;
  }

  /**
   * Form
   */
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      postingId: defaultPosting,
      cvId: '',
      sampleCoverLetterIds: [],
    },
  });

  const selectedCvId = methods.watch('cvId');
  const selectedCoverLetterIds = methods.watch('sampleCoverLetterIds');

  /**
   * Cache Manipulation
   */
  useEffect(() => {
    // we only want to save changes if the form has been initialized
    if (initializedValues.current === false) {
      return;
    }

    try {
      localStorage.setItem(`${cachePrefix}_${user?._id}_cv-id`, selectedCvId);
    } catch (e) {
      // could not write to localStorage
    }
  }, [selectedCvId, user?._id]);

  useEffect(() => {
    // we only want to save changes if the form has been initialized
    if (initializedValues.current === false) {
      return;
    }

    const value = (selectedCoverLetterIds || []).join(',');

    try {
      localStorage.setItem(`${cachePrefix}_${user?._id}_sample-cover-letter-ids`, value);
    } catch (e) {
      // could not write to localStorage
    }
  }, [selectedCoverLetterIds, user?._id]);

  /**
   * Initialize default form values after entities are loaded
   */
  useEffect(() => {
    if (initializedValues.current || documentsLoading || lettersLoading) {
      return;
    }

    if ((documents?.data || []).length > 0) {
      let cvChoice;

      try {
        const lastUsedCv = localStorage.getItem(`${cachePrefix}_${user?._id}_cv-id`);

        if (documents.data.some((v) => v._id === lastUsedCv)) {
          cvChoice = lastUsedCv;
        }
      } catch (e) {
        // could not read from localStorage
      }

      if (!cvChoice) {
        cvChoice = documents.data[0]._id;
      }

      methods.setValue('cvId', cvChoice);
    }

    if ((sampleCoverLetters?.data || []).length > 0) {
      let coverLettersChoice;

      try {
        const lastUsedCoverLetters = localStorage
          .getItem(`${cachePrefix}_${user?._id}_sample-cover-letter-ids`)
          .split(',');

        if (Array.isArray(lastUsedCoverLetters)) {
          coverLettersChoice = (lastUsedCoverLetters || []).filter((id) =>
            sampleCoverLetters.data.some((v) => v._id === id)
          );
        }
      } catch (e) {
        // could not read from localStorage
      }

      methods.setValue('sampleCoverLetterIds', coverLettersChoice);
    }

    initializedValues.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents, user?._id]);

  if (documentsLoading || lettersLoading) {
    return null;
  }

  const onSubmit = methods.handleSubmit(async (data) => {
    try {
      const result = await api.post(`/v1/projects/${data.postingId}/application-cover-letters`, {
        userId: user?._id,
        cvId: data.cvId || '',
        sampleCoverLetterIds: data.sampleCoverLetterIds || [],
      });

      mutate();
      handleChange(result.data.data._id)(undefined, true, true);
    } catch (error) {
      enqueueSnackbar(error.response?.data?.error || `Could not create application cover letter`, {
        variant: 'error',
      });
      console.error(error);
    }
  });

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack direction="column" spacing={3}>
        {/* list of postings */}
        {postings.length > 1 && (
          <Stack direction="column" spacing={0.5}>
            <Typography variant="body2">For which posting would you like to apply?</Typography>

            <RHFRadioGroup
              name="postingId"
              spacing={0}
              sx={{ '& .MuiRadio-sizeSmall': { py: 0.5 } }}
              size="small"
              options={postings.map((posting) => ({
                value: posting.projectId,
                label: <GroupApplicationCreatePostingLabel posting={posting} />,
              }))}
            />
          </Stack>
        )}

        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 2, sm: 2 }}
          sx={{ alignItems: 'center' }}
        >
          <RHFSelect name="cvId" label="Choose a CV">
            <MenuItem value="">None</MenuItem>
            <Divider sx={{ borderStyle: 'dashed' }} />
            {(documents?.data || []).map((item) => (
              <MenuItem key={item._id} value={item._id}>
                {item.fileInfo?.fileName}
              </MenuItem>
            ))}
          </RHFSelect>

          {(sampleCoverLetters?.data || []).length > 0 && (
            <RHFSelect
              name="sampleCoverLetterIds"
              label="Choose Cover Letters (optional)"
              SelectProps={{ multiple: true }}
            >
              {(sampleCoverLetters?.data || []).map((item) => (
                <MenuItem key={item._id} value={item._id}>
                  {item.title}
                </MenuItem>
              ))}
            </RHFSelect>
          )}

          <Box>
            <LoadingButton
              type="submit"
              color="primary"
              variant="contained"
              loading={methods.formState.isSubmitting}
            >
              Generate
            </LoadingButton>
          </Box>
        </Stack>
      </Stack>
    </FormProvider>
  );
}

GroupApplicationCreate.propTypes = {
  handleChange: PropTypes.func,
  groupId: PropTypes.string,
  user: PropTypes.object,
  postings: PropTypes.arrayOf(
    PropTypes.shape({
      projectId: PropTypes.string,
      postedAt: PropTypes.string,
      platform: PropTypes.string,
      sourceLink: PropTypes.string,
      status: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      details: PropTypes.shape({
        descriptionLanguage: PropTypes.string,
        authorCompany: PropTypes.string,
        authorName: PropTypes.string,
        hourlyRate: PropTypes.number,
        startDate: PropTypes.string,
        workload: PropTypes.string,
        durationInMonths: PropTypes.number,
        industry: PropTypes.string,
        contractType: PropTypes.string,
        workplace: PropTypes.string,
        location: PropTypes.string,
      }),
    })
  ),
};
