import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { paths } from 'src/routes/paths';
import AuthUserAware from 'src/routes/wrapper/auth-user-aware';
import InternalUserAware from 'src/routes/wrapper/internal-user-aware';

import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';
import ProjectGroupPage from 'src/pages/dashboard/management/group';
import WaitingListAdminDashboardPage from 'src/pages/dashboard/admin/waiting-list';
import PromptsBenchmarkDetailAdminDashboardPage from 'src/pages/dashboard/admin/prompts-benchmark-detail';

import { LoadingScreen } from 'src/components/loading-screen';

const BillingManagementDashboardPage = lazy(() => import('src/pages/dashboard/management/billing'));

const MaintenanceView = lazy(() => import('src/sections/error/maintenance-view'));
const PromptsAdminDashboardPage = lazy(() => import('src/pages/dashboard/admin/prompts'));
const PromptsNewAdminDashboardPage = lazy(() => import('src/pages/dashboard/admin/prompts-new'));
const PromptsOptimizeAdminDashboardPage = lazy(
  () => import('src/pages/dashboard/admin/prompts-optimize')
);
const PromptsBenchmarkAdminDashboardPage = lazy(
  () => import('src/pages/dashboard/admin/prompts-benchmark')
);
const PromptsEditAdminDashboardPage = lazy(() => import('src/pages/dashboard/admin/prompts-edit'));
const AdminDashboardProjectProvidersPage = lazy(
  () => import('src/pages/dashboard/admin/project-providers')
);
const AdminDashboardProjectProvidersStatsPage = lazy(
  () => import('src/pages/dashboard/admin/platforms-stats')
);
const ProjectProvidersNewAdminDashboardPage = lazy(
  () => import('src/pages/dashboard/admin/project-providers-new')
);
const ProjectProvidersEditAdminDashboardPage = lazy(
  () => import('src/pages/dashboard/admin/project-providers-edit')
);
const AppDashboardPage = lazy(() => import('src/pages/dashboard/overview/app'));
const BoardPage = lazy(() => import('src/pages/dashboard/management/board'));
const ProfileManagementDashboardPage = lazy(() => import('src/pages/dashboard/management/profile'));
const InvitesManagementDashboardPage = lazy(() => import('src/pages/dashboard/management/invites'));
const SubscriptionsManagementDashboardPage = lazy(
  () => import('src/pages/dashboard/management/subscriptions')
);
const ProjectsManagementDashboardPage = lazy(
  () => import('src/pages/dashboard/management/projects')
);
const ProjectDetailManagementDashboardPage = lazy(
  () => import('src/pages/dashboard/management/project-detail')
);
const PartnersManagementDashboardPage = lazy(
  () => import('src/pages/dashboard/management/partners')
);
const UsersAdminDashboardPage = lazy(() => import('src/pages/dashboard/admin/users'));
const StatisticsAdminPage = lazy(() => import('src/pages/dashboard/admin/statistics'));
const ActionEventsAdminPage = lazy(() => import('src/pages/dashboard/admin/action-events'));
const SettingsAdminPage = lazy(() => import('src/pages/dashboard/admin/settings'));
const CrawlAdminPage = lazy(() => import('src/pages/dashboard/admin/crawl'));

// ----------------------------------------------------------------------

const MAINTENANCE_MODE_ENABLED = false;

const overwriteRedirectTarget = (pathname, params) => {
  if (`${pathname}`.startsWith('/dashboard/management/group/')) {
    return paths.projectGroupDetails('unknown', params.id);
  }

  return undefined;
};

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard redirectTarget={overwriteRedirectTarget}>
        <AuthUserAware>
          <InternalUserAware>
            {MAINTENANCE_MODE_ENABLED ? (
              <MaintenanceView />
            ) : (
              <DashboardLayout>
                <Suspense fallback={<LoadingScreen />}>
                  <Outlet />
                </Suspense>
              </DashboardLayout>
            )}
          </InternalUserAware>
        </AuthUserAware>
      </AuthGuard>
    ),
    children: [
      { element: <AppDashboardPage />, index: true },
      { path: 'management/board/:userId?', element: <BoardPage /> },
      { path: 'management/group/:id/:userId?', element: <ProjectGroupPage /> },
      {
        path: 'management/profile/:userId',
        element: <ProfileManagementDashboardPage />,
      },
      {
        path: 'management/subscriptions/:userId',
        element: <SubscriptionsManagementDashboardPage />,
      },
      { path: 'management/projects/:userId', element: <ProjectsManagementDashboardPage /> },
      {
        path: 'management/projects/:userId/detail/:id',
        element: <ProjectDetailManagementDashboardPage />,
      },
      {
        path: 'management/billing/:userId',
        element: <BillingManagementDashboardPage />,
      },
      {
        path: 'management/invites/:userId',
        element: <InvitesManagementDashboardPage />,
      },
      {
        path: 'management/partners',
        element: <PartnersManagementDashboardPage />,
      },
      { path: 'admin/statistics', element: <StatisticsAdminPage /> },
      { path: 'admin/action-events', element: <ActionEventsAdminPage /> },
      { path: 'admin/users', element: <UsersAdminDashboardPage /> },
      { path: 'admin/waiting-list', element: <WaitingListAdminDashboardPage /> },
      { path: 'admin/prompts', element: <PromptsAdminDashboardPage /> },
      { path: 'admin/prompts/new', element: <PromptsNewAdminDashboardPage /> },
      { path: 'admin/prompts/:id', element: <PromptsEditAdminDashboardPage /> },
      { path: 'admin/prompts/optimize', element: <PromptsOptimizeAdminDashboardPage /> },
      { path: 'admin/prompts/benchmarks', element: <PromptsBenchmarkAdminDashboardPage /> },
      {
        path: 'admin/prompts/benchmarks/:id',
        element: <PromptsBenchmarkDetailAdminDashboardPage />,
      },
      { path: 'admin/project-providers', element: <AdminDashboardProjectProvidersPage /> },
      {
        path: 'admin/platforms/stats',
        element: <AdminDashboardProjectProvidersStatsPage />,
      },
      { path: 'admin/project-providers/new', element: <ProjectProvidersNewAdminDashboardPage /> },
      { path: 'admin/project-providers/:id', element: <ProjectProvidersEditAdminDashboardPage /> },
      { path: 'admin/settings', element: <SettingsAdminPage /> },
      { path: 'admin/crawl', element: <CrawlAdminPage /> },
    ],
  },
];
