import React from 'react';

import { Box, Link, Stack, Typography } from '@mui/material';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useTranslate } from 'src/locales';

import Image from 'src/components/image';
import { RootSection } from 'src/components/root-section/root-section';

const SITEMAP = [
  {
    title: 'Product',
    children: [
      { title: 'Home', href: '/' },
      { title: 'Benefits', href: '/#benefits' },
      { title: 'How it works', href: '/#how-it-works' },
      { title: 'Platforms', href: '/#platforms' },
      { title: 'Pricing', href: '/#pricing' },
      { title: 'FAQs', href: '/#faqs' },
    ],
  },
  {
    title: 'Company',
    children: [
      { title: 'Imprint', href: paths.imprint },
      { title: 'Privacy', href: paths.privacy },
      { title: 'Terms & Conditions', href: paths.terms },
    ],
  },
];

export default function FooterSitemapSection() {
  const { t } = useTranslate();

  const smoothScoll = (event) => {
    const clickedEl = event.target;
    const target = clickedEl.getAttribute('href');

    if (!`${target}`.startsWith('/#')) {
      return;
    }

    const targetElement = target ? document.querySelector(target.substring(1)) : undefined;

    if (targetElement) {
      event.preventDefault();
      targetElement.scrollIntoView({ behavior: 'smooth' });
      window.history.pushState(null, '', target);
    }
  };

  return (
    <RootSection>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={4}>
        <Box>
          <Image
            src="/logo/mono/logo-with-title.svg"
            alt="Logo"
            sx={{ width: 150, height: 'auto' }}
          />
        </Box>

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" spacing={4}>
          {SITEMAP.map(({ title, children }, index) => (
            <Stack key={title} direction="column" spacing={1} width={192}>
              <Typography variant="body2" fontWeight={600} color="text.quarternary" mb={1}>
                {t(title)}
              </Typography>

              {children.map(({ title: childTitle, href }, j) => (
                <Link
                  component={RouterLink}
                  key={childTitle}
                  href={href}
                  underline="none"
                  typography="body1"
                  color="text.ternary"
                  fontWeight={600}
                  onClick={smoothScoll}
                >
                  {t(childTitle)}
                </Link>
              ))}
            </Stack>
          ))}
        </Stack>
      </Stack>
    </RootSection>
  );
}
