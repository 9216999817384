import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import LinesEllipsisLoose from 'react-lines-ellipsis/lib/loose';

import { Box } from '@mui/system';
import Stack from '@mui/material/Stack';
import { Link, Tooltip, Typography } from '@mui/material';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useUserById } from 'src/hooks/use-user-by-id';

import { fDate } from 'src/utils/format-time';
import { fPercent } from 'src/utils/format-number';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function ProjectSimilarityContainer({ project, userId }) {
  const theme = useTheme();
  const { data: user } = useUserById(userId);

  return (
    <Stack spacing={0.5} direction="column">
      {project.similarity.similarProjects.map((ref, index) => {
        const optimizerLink = paths.dashboard.admin.prompts.optimize;
        const optimizerParams = new URLSearchParams({
          promptIdentifier: 'project-check-duplicate',
          projectId: project._id,
          projectCompareId: ref.projectId,
          userId: user._id,
        });

        let llmContent = (
          <Tooltip title="LLM Check has not been started because of low similarity">
            <Iconify width={16} icon="prime:thumbs-down" />
          </Tooltip>
        );

        if ('isDuplicate' in ref) {
          llmContent = (
            <Tooltip
              title={
                <span>
                  LLM decided with confidence {ref.confidence ?? 'unknown'}
                  <br />
                  <br />
                  {ref.reasoning || ''}
                </span>
              }
            >
              <Iconify
                width={20}
                icon={ref.isDuplicate ? 'prime:thumbs-up-fill' : 'prime:thumbs-down-fill'}
                color={ref.isDuplicate ? theme.palette.success.main : theme.palette.error.main}
              />
            </Tooltip>
          );
        }

        return (
          <Stack
            key={ref.projectId}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
            sx={{
              display: 'flex',
              width: 1,
              backgroundColor: index % 2 === 0 ? 'grey.100' : 'white',
            }}
          >
            <Typography variant="body2" sx={{ minWidth: '45px' }}>
              <span>{fPercent(ref.similarity * 100)}</span>
            </Typography>

            <Typography variant="body2" sx={{ minWidth: '45px' }}>
              <span>{ref.similarReason}</span>
            </Typography>

            <Link
              component={RouterLink}
              color="inherit"
              href={paths.dashboard.management.projectDetail(userId, ref.projectId)}
              sx={{ flexGrow: 1 }}
            >
              <Typography variant="body2" title={ref.title} sx={{ flexGrow: 1 }}>
                <LinesEllipsisLoose text={`${ref.title}`} maxLine="1" lineHeight="20" />
              </Typography>
            </Link>

            <Typography variant="body2" sx={{ ml: 1, minWidth: '95px', textAlign: 'right' }}>
              <span>{fDate(ref.foundAt)}</span>
            </Typography>

            <Box
              sx={{
                minWidth: '40px',
                flexGrow: 0,
              }}
            >
              <Link
                component={RouterLink}
                color="inherit"
                href={`${optimizerLink}?${optimizerParams}`}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {llmContent}
              </Link>
            </Box>
          </Stack>
        );
      })}
    </Stack>
  );
}

ProjectSimilarityContainer.propTypes = {
  userId: PropTypes.string,
  project: PropTypes.object,
};
