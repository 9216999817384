import PropTypes from 'prop-types';

import { Stack, CardHeader } from '@mui/material';

import { useScopes } from 'src/hooks/use-scopes';

import RootCard from 'src/components/root-card/root-card';

import CvDocumentsSidebarUploadContent from 'src/sections/cv-documents/sidebar-upload-content';

// ----------------------------------------------------------------------

export default function CvDocumentsSidebarUpload({ sx, userId }) {
  const { hasAllScopes } = useScopes();

  if (!hasAllScopes(['read:documents_own', 'write:documents_own'])) {
    return null;
  }

  return (
    <RootCard sx={{ p: 3, ...sx }}>
      <Stack spacing={2} alignItems="flex-start" direction="column">
        <CardHeader
          title="Your CVs"
          titleTypographyProps={{ variant: 'subtitle2' }}
          subheaderTypographyProps={{ variant: 'body2', color: 'text.ternary' }}
          sx={{ m: 0, p: 0 }}
        />

        <CvDocumentsSidebarUploadContent userId={userId} />
      </Stack>
    </RootCard>
  );
}

CvDocumentsSidebarUpload.propTypes = {
  userId: PropTypes.string,
  sx: PropTypes.object,
};
