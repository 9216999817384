import PropTypes from 'prop-types';
import React, { useRef, useMemo, useState, useEffect } from 'react';

import { Box, useTheme, useMediaQuery } from '@mui/material';

function randomColor() {
  const r = Math.floor(Math.random() * 200 + 55);
  const g = Math.floor(Math.random() * 200 + 55);
  const b = Math.floor(Math.random() * 200 + 55);
  return `rgb(${r}, ${g}, ${b})`;
}

const colorKeys = ['primary', 'secondary', 'info', 'success', 'warning', 'error'];
const shadeKeys = [100, 200, 300, 400];

function randomThemeColor(theme) {
  const key = colorKeys[Math.floor(Math.random() * colorKeys.length)];
  const shade = shadeKeys[Math.floor(Math.random() * shadeKeys.length)];
  return theme.palette[key][shade];
}

function generateBlobData(blobsPerViewport, viewportCount, theme) {
  const blobs = [];
  const firstPagePadding = 400; // First 100px should be blob-free

  for (let viewport = 0; viewport < viewportCount; viewport += 1) {
    for (let i = 0; i < blobsPerViewport; i += 1) {
      const viewportHeightInVh = 100;
      const viewportStartVh = viewport * viewportHeightInVh;
      let localPositionVh = Math.random() * viewportHeightInVh;
      let globalPositionVh = viewportStartVh + localPositionVh;

      // Convert first 100px to vh units
      const firstPixelsInVh = (firstPagePadding / window.innerHeight) * 100;

      // If this blob would be in the first 100px, reposition it
      if (viewport === 0 && localPositionVh < firstPixelsInVh) {
        // Position it after the first 100px but still in the first viewport
        localPositionVh = firstPixelsInVh + Math.random() * (viewportHeightInVh - firstPixelsInVh);
        globalPositionVh = localPositionVh; // In first viewport, global = local
      }

      blobs.push({
        topVh: globalPositionVh,
        left: Math.random() * 100,
        size: Math.random() * 150 + 200,
        colorStart: theme ? randomThemeColor(theme) : randomColor(),
        colorEnd: theme ? randomThemeColor(theme) : randomColor(),
        viewport: viewport + 1,
      });
    }
  }

  return blobs;
}

export default function BackgroundBlobs({ blobsPerViewport = { xs: 1, sm: 2, md: 4, lg: 6 } }) {
  const theme = useTheme();
  const containerRef = useRef(null);
  const lastHeightRef = useRef(0);
  const [blobs, setBlobs] = useState([]);

  // Breakpoint-Abfragen beim ersten Rendering
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));

  // Ermittle die Anzahl der Blobs basierend auf dem initialen Breakpoint
  const currentBlobsPerViewport = useMemo(() => {
    if (isXs) return blobsPerViewport.xs || 1;
    if (isSm) return blobsPerViewport.sm || 2;
    if (isMd) return blobsPerViewport.md || 4;
    if (isLg) return blobsPerViewport.lg || 6;
    return blobsPerViewport.md || 5; // Fallback
  }, [isXs, isSm, isMd, isLg, blobsPerViewport]);

  // Überwacht die Höhe und aktualisiert die Blobs nur bei einer Änderung von mindestens 1000px
  useEffect(() => {
    const checkForSignificantHeightChange = () => {
      if (!containerRef.current || !containerRef.current.parentElement) return;

      const parentHeight = containerRef.current.parentElement.scrollHeight;
      const heightDifference = Math.abs(parentHeight - lastHeightRef.current);

      // Nur neu berechnen, wenn die Höhenänderung mindestens 1000px beträgt
      if (heightDifference >= 1000 || lastHeightRef.current === 0) {
        const viewportHeight = window.innerHeight;
        const viewportCount = Math.ceil(parentHeight / viewportHeight);

        lastHeightRef.current = parentHeight;
        setBlobs(generateBlobData(currentBlobsPerViewport, viewportCount, theme));
      }
    };

    // Initial berechnen
    checkForSignificantHeightChange();

    // ResizeObserver, um signifikante Höhenänderungen zu erkennen
    const resizeObserver = new ResizeObserver(checkForSignificantHeightChange);
    if (containerRef.current && containerRef.current.parentElement) {
      resizeObserver.observe(containerRef.current.parentElement);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [currentBlobsPerViewport, theme]);
  // Keine window.addEventListener für 'resize', da wir nur auf Höhenänderungen des Containers reagieren

  return (
    <Box
      ref={containerRef}
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        zIndex: -1,
      }}
    >
      {blobs.map((blob, index) => (
        <Box
          key={index}
          sx={{
            position: 'absolute',
            borderRadius: '50%',
            filter: 'blur(100px)',
            opacity: 0.2,
            transform: 'translate(-50%, -50%)',
            top: `${blob.topVh}vh`,
            left: `${blob.left}%`,
            width: blob.size,
            height: blob.size,
            background: `radial-gradient(circle at 50% 50%,
                          ${blob.colorStart},
                          ${blob.colorEnd})`,
            zIndex: -1,
          }}
        />
      ))}
    </Box>
  );
}

BackgroundBlobs.propTypes = {
  blobsPerViewport: PropTypes.shape({
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
  }),
};
