import React from 'react';
import PropTypes from 'prop-types';

import { Container } from '@mui/material';

import { HEADER_SIZE_MOBILE, HEADER_SIZE_DESKTOP } from 'src/layouts/root/vars';

export function RootSection({ children, sx, fullViewport, ...other }) {
  return (
    <Container
      maxWidth="lg"
      component="section"
      sx={{
        ...(fullViewport && {
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          minHeight: '100vh',
          mt: {
            xs: `-${HEADER_SIZE_MOBILE}px`,
            md: `-${HEADER_SIZE_DESKTOP}px`,
          },
        }),
        py: { xs: 10, sm: 15 },
        ...sx,
      }}
      {...other}
    >
      {children}
    </Container>
  );
}

RootSection.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  sx: PropTypes.object,
  fullViewport: PropTypes.bool,
};
