import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const Logo = forwardRef(
  ({ size = 'medium', disabledLink = false, includeName = false, sx, ...other }, ref) => {
    const { pathname } = useLocation();

    // OR using local (public folder)
    // -------------------------------------------------------
    let logo = (
      <Box
        component="img"
        src="/logo/color/logo.svg"
        sx={{ cursor: 'pointer', mx: 'auto', ...sx }}
      />
    );

    let logoWithName = (
      <Box
        component="img"
        src="/logo/color/logo-with-title.svg"
        sx={{ width: '100%', cursor: 'pointer', ...sx }}
      />
    );

    if (!disabledLink) {
      logo = (
        <Link component={RouterLink} href={pathname?.includes('dashboard') ? '/dashboard' : '/'}>
          {logo}
        </Link>
      );

      logoWithName = (
        <Link component={RouterLink} href={pathname?.includes('dashboard') ? '/dashboard' : '/'}>
          {logoWithName}
        </Link>
      );
    }

    return includeName ? logoWithName : logo;
  }
);

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  includeName: PropTypes.bool,
  size: PropTypes.string,
  sx: PropTypes.object,
};

export default Logo;
