/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { useSnackbar } from 'notistack';
import { useState, useEffect, useCallback } from 'react';

import {
  Stack,
  Table,
  Portal,
  Tooltip,
  Divider,
  TableRow,
  Backdrop,
  TableBody,
  TableCell,
  IconButton,
  Typography,
  Pagination,
  TableContainer,
  TableSortLabel,
} from '@mui/material';

import { useApi } from 'src/hooks/use-api';
import { useScopes } from 'src/hooks/use-scopes';
import { useBoolean } from 'src/hooks/use-boolean';

import Iconify from 'src/components/iconify';
import { TableHeadCustom } from 'src/components/table';
import RootCard from 'src/components/root-card/root-card';

// ----------------------------------------------------------------------

const ACTION_EVENTS_VIEWER_ORDER = 'action-events-viewer-order';

const ZINDEX = 1998;
const POSITION = 24;

// ----------------------------------------------------------------------

export function ActionEventsViewer({ userId }) {
  const { hasScope } = useScopes();
  const { enqueueSnackbar } = useSnackbar();
  const logsShown = useBoolean(false);
  const orderAsc = useBoolean(localStorage.getItem(ACTION_EVENTS_VIEWER_ORDER) === 'asc');

  useEffect(() => {
    localStorage.setItem(ACTION_EVENTS_VIEWER_ORDER, orderAsc.value ? 'asc' : 'desc');
  }, [orderAsc.value]);

  /**
   * Data Fetching
   */
  const api = useApi();
  const itemsPerPage = 25;
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(null);
  const [logs, setLogs] = useState([]);

  const loadLogs = useCallback(async () => {
    if (!userId) {
      setLogs([]);
      setMaxPages(0);
      return;
    }

    try {
      const result = await api.get('/v1/action-events', {
        params: {
          from: (currentPage - 1) * itemsPerPage,
          limit: itemsPerPage,
          order: orderAsc.value ? 'asc' : 'desc',
          userId,
          ignoreAdmins: false,
        },
      });

      setLogs(result.data.data);
      setMaxPages(Math.ceil((result.data?.metadata?.[0]?.total || 0) / itemsPerPage));
    } catch (error) {
      Sentry.captureException(error);
      enqueueSnackbar('Failed to fetch logs', { variant: 'error' });
      console.error(error);
    }
  }, [userId, api, currentPage, orderAsc.value, enqueueSnackbar]);

  useEffect(() => {
    if (!logsShown.value) {
      return;
    }

    loadLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, orderAsc.value, logsShown.value]);

  // bind key listener to ESCAPE to close the logShown var
  useEffect(() => {
    if (!logsShown.value) {
      return () => {};
    }

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        logsShown.onFalse();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [logsShown]);

  if (!hasScope('admin:users')) {
    return null;
  }

  return (
    <>
      {logsShown.value && (
        <Portal>
          <Backdrop open sx={{ zIndex: ZINDEX }} />

          <RootCard
            sx={{
              p: 0,
              borderRadius: 2,
              display: 'flex',
              position: 'fixed',
              zIndex: ZINDEX + 1,
              flexDirection: 'column',
              boxShadow: (theme) => theme.customShadows.dropdown,
              m: 0,
              right: POSITION / 2,
              bottom: POSITION / 2,
              width: `calc(100% - ${POSITION}px)`,
              height: `calc(100% - ${POSITION}px)`,
              overflow: 'hidden',
            }}
          >
            <Stack direction="column" spacing={0} sx={{ overflow: 'scroll' }}>
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  bgcolor: 'background.neutral',
                  p: (theme) => theme.spacing(1.5, 1, 1.5, 2),
                }}
              >
                <Typography variant="h6" sx={{ flexGrow: 1 }}>
                  Action Events{logs?.[0]?.userEmail ? ` for ${logs?.[0]?.userEmail}` : ''}
                </Typography>

                <IconButton onClick={logsShown.onFalse}>
                  <Iconify icon="mingcute:close-line" />
                </IconButton>
              </Stack>

              {!userId === 0 && (
                <Typography variant="body1" sx={{ mt: 3, p: 2 }}>
                  No userId given ...
                </Typography>
              )}

              <TableContainer sx={{ overflow: 'unset' }}>
                <Table>
                  <TableHeadCustom
                    headLabel={[
                      {
                        id: 'createdAt',
                        label: (
                          <TableSortLabel
                            hideSortIcon
                            active
                            direction={orderAsc.value ? 'asc' : 'desc'}
                            onClick={orderAsc.onToggle}
                          >
                            Date
                          </TableSortLabel>
                        ),
                      },
                      { id: 'type', label: 'Type' },
                      { id: 'attributes', label: 'Attributes' },
                    ]}
                  />

                  <TableBody>
                    {(logs || []).map((row) => (
                      <TableRow key={row._id}>
                        <TableCell sx={{ width: '175px' }}>
                          {new Date(row.createdAt).toLocaleString()}
                        </TableCell>

                        <TableCell sx={{ width: '350px' }}>{row.type}</TableCell>

                        <TableCell sx={{ flexGrow: 1 }}>
                          {Object.keys(row.attributes || {})
                            .map(
                              (key) =>
                                `${key}: ${
                                  typeof row.attributes[key] === 'boolean'
                                    ? row.attributes[key]
                                      ? 'true'
                                      : 'false'
                                    : row.attributes[key]
                                }`
                            )
                            .join(', ')}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

                {maxPages > 1 && (
                  <>
                    <Divider />
                    <Stack sx={{ p: 1, width: 1 }} alignItems="center">
                      <Pagination
                        count={maxPages}
                        page={currentPage}
                        siblingCount={0}
                        boundaryCount={1}
                        onChange={(_, newValue) => {
                          setCurrentPage(newValue);
                        }}
                      />
                    </Stack>
                  </>
                )}
              </TableContainer>
            </Stack>
          </RootCard>
        </Portal>
      )}

      <Tooltip placement="top" arrow title="Show action events">
        <IconButton onClick={logsShown.onToggle}>
          <Iconify icon="solar:user-speak-bold" width={24} />
        </IconButton>
      </Tooltip>
    </>
  );
}

ActionEventsViewer.propTypes = {
  userId: PropTypes.string,
};
