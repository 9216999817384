import { m } from 'framer-motion';

import Box from '@mui/material/Box';
import { Badge } from '@mui/material';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import { alpha } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useUserById } from 'src/hooks/use-user-by-id';
import { useAuthState } from 'src/hooks/use-auth-state';

import { isUserProfileIncomplete } from 'src/utils/is-user-profile-incomplete';

import { varHover } from 'src/components/animate';
import { useSnackbar } from 'src/components/snackbar';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const router = useRouter();

  const { logout } = useAuthState();
  const { data: user } = useUserById();

  const isProfileIncomplete = isUserProfileIncomplete(user);

  const { enqueueSnackbar } = useSnackbar();

  const popover = usePopover();

  const handleLogout = async () => {
    try {
      await logout({ logoutParams: { returnTo: window.location.origin } });
      popover.onClose();
      router.replace('/');
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleClickItem = (path) => {
    popover.onClose();
    router.push(path);
  };

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        {isProfileIncomplete ? (
          <Badge
            variant="dot"
            color="error"
            slotProps={{
              badge: { sx: { width: '12px', height: '12px', top: '5px', right: '5px' } },
            }}
          >
            <Avatar
              src={undefined}
              alt={user?.full_name}
              sx={{
                width: 36,
                height: 36,
                border: (theme) => `solid 2px ${theme.palette.background.default}`,
              }}
            >
              {user?.full_name?.charAt(0).toUpperCase()}
            </Avatar>
          </Badge>
        ) : (
          <Avatar
            src={undefined}
            alt={user?.full_name}
            sx={{
              width: 36,
              height: 36,
              border: (theme) => `solid 2px ${theme.palette.background.default}`,
            }}
          >
            {user?.full_name?.charAt(0).toUpperCase()}
          </Avatar>
        )}
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="body2" noWrap fontWeight="600">
            {user?.full_name}
          </Typography>

          <Typography variant="caption" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email_address}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          <MenuItem onClick={() => handleClickItem('/dashboard')}>Home</MenuItem>
          <MenuItem onClick={() => handleClickItem(paths.dashboard.management.profile(user._id))}>
            Profile
            {isProfileIncomplete && <Badge color="error" variant="dot" sx={{ ml: 1.5 }} />}
          </MenuItem>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={handleLogout}
          sx={{ m: 1, fontWeight: 'fontWeightSemiBold', color: 'error.main' }}
        >
          Logout
        </MenuItem>
      </CustomPopover>
    </>
  );
}
