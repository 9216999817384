import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import { Stack, Tooltip, useTheme, CardHeader, Typography, IconButton } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';
import { useSampleCoverLetters } from 'src/hooks/use-sample-cover-letters';

import Iconify from 'src/components/iconify';
import RootCard from 'src/components/root-card/root-card';
import SampleCoverLettersRow from 'src/components/sample-cover-letters-row';
import SampleCoverLettersRowSkeleton from 'src/components/sample-cover-letters-row-skeleton';

import SampleCoverLettersModal from 'src/sections/sample-cover-letters/modal';

// ----------------------------------------------------------------------

export default function SampleCoverLettersSidebarCard({ sx, userId }) {
  const theme = useTheme();
  const modalOpen = useBoolean();
  const [editItem, setEditItem] = useState(null);
  const { data: coverLetters, mutate, isLoading } = useSampleCoverLetters(userId);

  const items = useMemo(() => coverLetters?.data || [], [coverLetters]);
  const maxItemsReached = items.length >= 6;

  return (
    <>
      <RootCard sx={{ p: 3, ...sx }}>
        <Stack spacing={2} alignItems="flex-start" direction="column">
          <CardHeader
            titleTypographyProps={{ variant: 'subtitle2' }}
            subheaderTypographyProps={{ variant: 'body2', color: 'text.ternary' }}
            title={
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="subtitle2">Example Cover Letters</Typography>

                <Tooltip
                  placement="top"
                  arrow
                  title={
                    maxItemsReached
                      ? 'You have reached the maximum number of personal cover letters'
                      : 'Create a new personal cover letter'
                  }
                >
                  <IconButton
                    size="small"
                    onClick={() => {
                      if (!maxItemsReached) {
                        modalOpen.onTrue();
                      }
                    }}
                  >
                    <Iconify
                      color={maxItemsReached ? theme.palette.grey : theme.palette.primary.main}
                      variant="primary"
                      icon="solar:add-circle-bold"
                      width={20}
                      height={20}
                    />
                  </IconButton>
                </Tooltip>
              </Stack>
            }
            sx={{ width: 1, p: 0, m: 0 }}
          />

          <Typography variant="caption">
            By providing us some of your personal cover letters, we are able to match the style and
            tone of new ones.
          </Typography>

          {isLoading && (
            <Stack spacing={1} alignItems="flex-start" direction="column" sx={{ width: 1 }}>
              {Array.from({ length: 3 }).map((_, index) => (
                <SampleCoverLettersRowSkeleton key={index} />
              ))}
            </Stack>
          )}

          {!isLoading && items.length > 0 && (
            <Stack spacing={1} alignItems="flex-start" direction="column" sx={{ width: 1 }}>
              {items.map((coverLetter) => (
                <SampleCoverLettersRow
                  key={coverLetter._id}
                  userId={userId}
                  coverLetter={coverLetter}
                  onDelete={mutate}
                  onEdit={() => {
                    setEditItem(coverLetter);
                    modalOpen.onTrue();
                  }}
                />
              ))}
            </Stack>
          )}
        </Stack>
      </RootCard>

      <SampleCoverLettersModal
        editItem={editItem}
        userId={userId}
        shown={modalOpen.value}
        onClose={() => {
          modalOpen.onFalse();
          setEditItem(null);
        }}
      />
    </>
  );
}

SampleCoverLettersSidebarCard.propTypes = {
  userId: PropTypes.string,
  sx: PropTypes.object,
};
