// ----------------------------------------------------------------------

import { useTranslation } from 'react-i18next';

import { Box, Stack, Container, Typography } from '@mui/material';

export default function Footer() {
  const { t } = useTranslation();

  return (
    <Container maxWidth="lg" component="footer" sx={{ mt: 15, pb: 10 }}>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        spacing={3}
        alignItems={{ xs: 'flex-start', md: 'flex-end' }}
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="subtitle1" gutterBottom>
            {t('Work that works for you.')}
          </Typography>
          <Typography variant="body2" color="text.quarternary" fontWeight={600} maxWidth={420}>
            {t(
              'Quality freelance opportunities without the endless search. AI-matched to your exact expertise.'
            )}
          </Typography>
        </Box>

        <Box>
          <Typography variant="body1" color="text.quarternary" fontWeight={600}>
            {t('©2025 projectfit.ai - All rights reserved.')}
          </Typography>
        </Box>
      </Stack>
    </Container>
  );
}
