import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { useMemo, useState, useEffect, useCallback } from 'react';

import {
  Box,
  Stack,
  Portal,
  Tooltip,
  Backdrop,
  IconButton,
  Typography,
  TableContainer,
} from '@mui/material';

import { useApi } from 'src/hooks/use-api';
import { useScopes } from 'src/hooks/use-scopes';
import { useBoolean } from 'src/hooks/use-boolean';
import { useAuthState } from 'src/hooks/use-auth-state';

import { fDateTime } from 'src/utils/format-time';

import ProjectSimilarityContainer from 'src/pages/dashboard/management/project-detail/similarity-container';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import RootCard from 'src/components/root-card/root-card';

// ----------------------------------------------------------------------

const ZINDEX = 1103;
const POSITION = 24;

// ----------------------------------------------------------------------

export function DuplicationInfoViewer({ dependencies }) {
  const { hasScope } = useScopes();
  const { userId } = useAuthState();

  const postingIds = useMemo(() => dependencies?.postingIds || [], [dependencies?.postingIds]);
  const dialogShown = useBoolean(false);

  /**
   * Data Fetching
   */
  const api = useApi();
  const isLoading = useBoolean(false);
  const [projects, setProjects] = useState([]);

  const loadProject = useCallback(
    async (projectId) => {
      try {
        const result = await api.get(`/v1/projects/${projectId}`, { params: { userId } });
        return result.data;
      } catch (error) {
        Sentry.captureException(error);
        console.error(error);
      }

      return undefined;
    },
    [api, userId]
  );

  useEffect(() => {
    isLoading.onTrue();

    const promises = postingIds.map(loadProject);

    Promise.all(promises)
      .then(setProjects)
      .then(() => isLoading.onFalse())
      .catch(() => isLoading.onFalse());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postingIds]);

  if (!hasScope('admin:users')) {
    return null;
  }

  return (
    <>
      {dialogShown.value && (
        <Portal>
          <Backdrop open sx={{ zIndex: ZINDEX }} />

          <RootCard
            sx={{
              p: 0,
              borderRadius: 2,
              display: 'flex',
              position: 'fixed',
              zIndex: ZINDEX + 1,
              flexDirection: 'column',
              boxShadow: (theme) => theme.customShadows.dropdown,
              m: 0,
              right: POSITION / 2,
              bottom: POSITION / 2,
              width: `calc(100% - ${POSITION}px)`,
              height: `calc(100% - ${POSITION}px)`,
              overflow: 'hidden',
            }}
          >
            <Stack direction="column" spacing={3} sx={{ overflow: 'scroll', flexGrow: 1 }}>
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  bgcolor: 'background.neutral',
                  p: (theme) => theme.spacing(1.5, 1, 1.5, 2),
                }}
              >
                <Typography variant="h6" sx={{ flexGrow: 1 }}>
                  Duplication Infos
                </Typography>

                <IconButton onClick={dialogShown.onFalse}>
                  <Iconify icon="mingcute:close-line" />
                </IconButton>
              </Stack>

              {(projects || []).length === 0 && (
                <Typography variant="body1" sx={{ p: 2 }}>
                  No projects found for the current group.
                </Typography>
              )}

              <TableContainer sx={{ flexGrow: 1, p: 2 }}>
                <Scrollbar>
                  <Stack direction="column" spacing={3}>
                    {projects.map((project) => (
                      <Box key={project._id}>
                        <Stack direction="column" spacing={1}>
                          <Box sx={{ borderRadius: '4px', backgroundColor: 'grey.200', p: 0.5 }}>
                            <Stack direction="row" alignItems="center" sx={{ p: 1 }}>
                              <Typography variant="body2" sx={{ fontWeight: 'semiBold' }}>
                                {project.crawlerDetails.title}
                              </Typography>

                              <Typography variant="body2" sx={{ color: 'text.disabled', ml: 1 }}>
                                {fDateTime(project.foundAt)}
                              </Typography>
                            </Stack>
                          </Box>

                          <Box sx={{ px: 2 }}>
                            <ProjectSimilarityContainer project={project} userId={userId} />
                          </Box>
                        </Stack>
                      </Box>
                    ))}
                  </Stack>
                </Scrollbar>
              </TableContainer>
            </Stack>
          </RootCard>
        </Portal>
      )}

      <Tooltip placement="top" arrow title="Show duplication infos">
        <IconButton onClick={dialogShown.onToggle}>
          <Iconify icon="garden:duplicate-fill-12" width={24} />
        </IconButton>
      </Tooltip>
    </>
  );
}

DuplicationInfoViewer.propTypes = {
  dependencies: PropTypes.shape({
    postingIds: PropTypes.arrayOf(PropTypes.string),
  }),
};
