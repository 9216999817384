import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import LinesEllipsisLoose from 'react-lines-ellipsis/lib/loose';

import { Box } from '@mui/system';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { Link, Typography, ListItemText } from '@mui/material';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import matchLabel from 'src/utils/match-label';

import PromptsBenchmarkSampleLlmItem from 'src/pages/dashboard/admin/prompts-benchmark/sample-llm-item';

// ----------------------------------------------------------------------

export default function PromptsBenchmarkSampleRow({
  sample,
  llmResults,
  promptIdentifier,
  promptVersion,
}) {
  const theme = useTheme();

  const matchingColor = (expected, actual) =>
    expected === actual ? theme.palette.success.light : theme.palette.error.lighter;

  let referenceType = 'matching';
  let referenceLevel = sample.matching.level;

  if (sample.opinion) {
    referenceType = 'opinion';
    referenceLevel = sample.opinion.level;
  }

  return (
    <>
      <Grid xs={5}>
        <ListItemText
          primary={
            <Link
              component={RouterLink}
              to={paths.dashboard.management.projectDetail(sample.user._id, sample.project._id)}
              color="inherit"
            >
              <span title={sample.project.title}>
                <LinesEllipsisLoose
                  sx={{ color: 'black', textDecoration: 'none' }}
                  text={sample.project.title}
                  maxLine="1"
                  lineHeight="16"
                />
              </span>
            </Link>
          }
          secondary={
            <Link
              component={RouterLink}
              to={paths.dashboard.management.profile(sample.user._id)}
              color="inherit"
            >
              <span title={sample.user.fullName}>
                <LinesEllipsisLoose text={sample.user.fullName} maxLine="1" lineHeight="16" />
              </span>
            </Link>
          }
        />
      </Grid>

      {sample.opinion && (
        <Grid
          xs={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" sx={{ fontWeight: 'semiBold' }}>
            {matchLabel(sample.opinion.level).split(' ')[0]}
          </Typography>
        </Grid>
      )}

      <Grid
        xs={1}
        sx={{
          backgroundColor:
            referenceType === 'opinion'
              ? matchingColor(referenceLevel, sample.matching.level)
              : undefined,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption">{matchLabel(sample.matching.level).split(' ')[0]}</Typography>
      </Grid>
      <Grid xs={sample.opinion ? 5 : 6} sx={{ p: 0, display: 'flex' }}>
        {(sample.llmItems || []).length === 0 && (
          <Box
            sx={{
              width: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="caption">Not yet started</Typography>
          </Box>
        )}

        {(sample.llmItems || []).map((item) => (
          <PromptsBenchmarkSampleLlmItem
            key={item.llmRef}
            item={item}
            repeatCount={sample.llmItems.length}
            expectedLevel={sample.opinion.level}
            llmResults={llmResults}
            promptIdentifier={promptIdentifier}
            promptVersion={promptVersion}
            sample={sample}
          />
        ))}
      </Grid>
    </>
  );
}

PromptsBenchmarkSampleRow.propTypes = {
  sample: PropTypes.object.isRequired,
  llmResults: PropTypes.object,
  promptIdentifier: PropTypes.string.isRequired,
  promptVersion: PropTypes.number.isRequired,
};
