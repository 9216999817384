import PropTypes from 'prop-types';

import { Box, Link, Tooltip, Typography } from '@mui/material';

import { RouterLink } from 'src/routes/components';

import Image from 'src/components/image';

// ----------------------------------------------------------------------

export default function GroupInfoRobertAnalysis({ personalAnalysis }) {
  if (!personalAnalysis) {
    return null;
  }

  return (
    <Box sx={{ px: 2 }}>
      <Typography variant="body2" component="div">
        <Link component={RouterLink} href="/robert" sx={{ float: 'left' }}>
          <Tooltip title="Hi, I'm Robert – nice to meet you!" placement="top" arrow>
            <Image src="/logo/color/logo.svg" sx={{ height: 35, width: 57, mr: 2, mt: 0.5 }} />
          </Tooltip>
        </Link>
        {personalAnalysis}
      </Typography>
    </Box>
  );
}

GroupInfoRobertAnalysis.propTypes = {
  personalAnalysis: PropTypes.string,
};
