// ----------------------------------------------------------------------

import { palette } from 'src/theme/palette';

export function remToPx(value) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes({ xs, sm, md, lg }) {
  return {
    '@media (min-width:0px)': { fontSize: pxToRem(xs) },
    '@media (min-width:600px)': { fontSize: pxToRem(sm) },
    '@media (min-width:900px)': { fontSize: pxToRem(md) },
    '@media (min-width:1200px)': { fontSize: pxToRem(lg) },
  };
}

export const primaryFont = 'Inter, serif';
export const secondaryFont = 'InterTight, serif';

// ----------------------------------------------------------------------

const colors = palette('light');

export const typography = {
  fontFamily: primaryFont,
  fontSecondaryFamily: secondaryFont,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,

  h1: {
    fontFamily: secondaryFont,
    color: colors.text.primary,
    fontWeight: 600,
    lineHeight: 90 / 72,
    letterSpacing: '-2%',
    ...responsiveFontSizes({ xs: 54, sm: 58, md: 65, lg: 72 }), // Display 2xl
  },
  h2: {
    fontFamily: secondaryFont,
    color: colors.text.primary,
    fontWeight: 600,
    lineHeight: 72 / 60,
    letterSpacing: '-2%',
    ...responsiveFontSizes({ xs: 45, sm: 48, md: 54, lg: 60 }), // Display xl
  },
  h3: {
    fontFamily: secondaryFont,
    color: colors.text.primary,
    fontWeight: 600,
    lineHeight: 60 / 48,
    letterSpacing: '-2%',
    ...responsiveFontSizes({ xs: 36, sm: 38, md: 43, lg: 48 }), // Display lg
  },
  h4: {
    fontFamily: secondaryFont,
    color: colors.text.primary,
    fontWeight: 600,
    lineHeight: 44 / 36,
    letterSpacing: '-2%',
    ...responsiveFontSizes({ xs: 27, sm: 29, md: 32, lg: 36 }), // Display md
  },
  h5: {
    fontFamily: secondaryFont,
    color: colors.text.primary,
    fontWeight: 600,
    lineHeight: 38 / 30,
    letterSpacing: '-2%',
    ...responsiveFontSizes({ xs: 22, sm: 24, md: 27, lg: 30 }), // Display sm
  },
  h6: {
    fontFamily: secondaryFont,
    color: colors.text.primary,
    fontWeight: 600,
    lineHeight: 32 / 24,
    letterSpacing: '-2%',
    ...responsiveFontSizes({ xs: 18, sm: 19, md: 22, lg: 24 }), // Display xs
  },
  subtitle1: {
    fontFamily: primaryFont,
    color: colors.text.secondary,
    fontWeight: 600,
    lineHeight: 30 / 20,
    ...responsiveFontSizes({ xs: 20, sm: 20, md: 20, lg: 20 }), // Text xl
  },
  subtitle2: {
    fontFamily: primaryFont,
    color: colors.text.secondary,
    fontWeight: 600,
    lineHeight: 26 / 18,
    ...responsiveFontSizes({ xs: 18, sm: 18, md: 18, lg: 18 }), // Text lg
  },
  body1: {
    fontFamily: primaryFont,
    color: colors.text.secondary,
    lineHeight: 24 / 16,
    ...responsiveFontSizes({ xs: 16, sm: 16, md: 16, lg: 16 }), // Text md
  },
  body2: {
    fontFamily: primaryFont,
    color: colors.text.secondary,
    lineHeight: 22 / 14,
    ...responsiveFontSizes({ xs: 14, sm: 14, md: 14, lg: 14 }), // Text sm
  },
  caption: {
    fontFamily: primaryFont,
    color: colors.text.secondary,
    lineHeight: 18 / 12,
    ...responsiveFontSizes({ xs: 12, sm: 12, md: 12, lg: 12 }), // Text xs
  },
  overline: {
    fontFamily: primaryFont,
    color: colors.text.secondary,
    fontWeight: 600,
    lineHeight: 18 / 12,
    fontSize: 12,
  },
  button: {
    fontFamily: primaryFont,
    color: colors.text.secondary,
    fontWeight: 600,
    lineHeight: 24 / 14,
    textTransform: 'unset',
    fontSize: 14,
  },
};
