import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import React, { useMemo, useState, useCallback } from 'react';

import { useApi } from 'src/hooks/use-api';

import { AuthContext } from 'src/components/auth/context/auth-context';

const LS_SESSION_STATE_KEY = 'auth-session-state';

// Hilfsfunktion zum Auslesen und Validieren des Session-States aus dem Local Storage
function getLocalStorageValue() {
  let localStorageValue = {};

  try {
    localStorageValue = localStorage.getItem(LS_SESSION_STATE_KEY)
      ? JSON.parse(localStorage.getItem(LS_SESSION_STATE_KEY))
      : {};
  } catch (error) {
    Sentry.captureException(new Error('Failed to parse auth session state from localStorage'));
  }

  if (localStorageValue?.sessionExpiresAt) {
    const sessionExpiresAt = new Date(localStorageValue.sessionExpiresAt);
    const now = new Date();

    if (sessionExpiresAt < now) {
      localStorage.removeItem(LS_SESSION_STATE_KEY);
      localStorageValue = {};
    }
  }

  console.log('Returned LS Value', localStorageValue);

  return localStorageValue;
}

export function AuthProvider({ children }) {
  const api = useApi();

  const lsValue = useMemo(() => getLocalStorageValue(), []);
  const [authSession, setAuthSession] = useState(lsValue);

  // login und logout mit useCallback stabilisieren
  const login = useCallback(
    async (username, password) => {
      const response = await api.post('/v1/auth/login', {
        username,
        password,
      });

      if (response.data?.status !== 'success') {
        throw new Error('Ein Fehler ist aufgetreten. Bitte versuche es später erneut.');
      }

      const sessionData = {
        loggedIn: true,
        userId: response.data.userId,
        scopes: response.data.scopes,
        sessionExpiresAt: response.data.sessionExpiresAt,
      };

      localStorage.setItem(LS_SESSION_STATE_KEY, JSON.stringify(sessionData));
      setAuthSession(sessionData);
    },
    [api] // api bleibt hier stabil genug, falls du es nicht in  useCallback  packen willst
  );

  const logout = useCallback(async () => {
    try {
      await api.post('/v1/auth/logout');
    } catch (error) {
      Sentry.captureException(error);
    }

    localStorage.removeItem(LS_SESSION_STATE_KEY);
    setAuthSession({});
    window.location.href = '/';
  }, [api]);

  // value in useMemo packen, um das Objekt bei unveränderten Werten zu "cachen".
  const value = useMemo(
    () => ({
      authenticated: authSession.loggedIn || false,
      userId: authSession.userId || null,
      scopes: authSession.scopes || [],
      sessionExpiresAt: authSession.sessionExpiresAt || null,
      login,
      logout,
    }),
    [authSession, login, logout]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

// PropTypes für den AuthProvider
AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
