export const PLATFORM_LABELS = {
  freelancermap: 'freelancermap',
  freelancede: 'freelance.de',
  gulp: 'Randstad Professional',
  randstad: 'Randstad Professional',
  hays: 'Hays',
  solcom: 'SOLCOM',
  nemensis: 'nemensis',
  emagine: 'emagine',
  constaff: 'CONSTAFF',
  etengo: 'Etengo',
  michaelpage: 'Michael Page',
  computerfutures: 'Computer Futures',
  percision: 'percision.',
  progressive: 'Progressive Recruitment',
  amoriabond: 'Amoria Bond',
  topitservices: 'top itservices',
  soorce: 'SOORCE',
  eightbitexperts: 'eightbit experts',
  contractor: 'CONTRACTOR',
  halian: 'Halian',
  dygitized: 'DYGITIZED',
  avantgarde_experts: 'AVANTGARDE Experts',
  findyou: 'FindYou',
  apriori: 'Apriori',
  secustaff: 'SECUSTAFF',
  thryve: 'Thryve Talent',
  krongaard: 'KRONGAARD',
  g2recruitment: 'g2 Recruitment',
};

export default function platformLabel(platform) {
  const platformKey = `${platform}`.toLowerCase();
  const knownPlatforms = Object.keys(PLATFORM_LABELS);

  if (knownPlatforms.includes(platformKey)) {
    return PLATFORM_LABELS[platformKey];
  }

  console.log(`unknown platform: ${platform}`);

  return platform;
}
