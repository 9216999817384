import PropTypes from 'prop-types';

import { Stack, Typography } from '@mui/material';

import ProjectSkill from 'src/components/project-skill';

// ----------------------------------------------------------------------

export default function GroupInfoMissingSkills({ missingSkills, userId, userSkills }) {
  const filteredSkills = missingSkills.filter((v) => !(userSkills || []).includes(v));

  if (filteredSkills.length === 0) {
    return null;
  }

  return (
    <Stack direction="column" spacing={2} sx={{ px: 2 }}>
      <Typography variant="body2" sx={{ fontWeight: 'semiBold' }}>
        Missing Skills
      </Typography>

      <Stack direction="row" flexWrap="wrap" spacing={1}>
        {filteredSkills.map((tag) => (
          <ProjectSkill
            source="project-group"
            key={tag}
            skill={tag}
            userId={userId}
            showOnlyMissing
          />
        ))}
      </Stack>
    </Stack>
  );
}

GroupInfoMissingSkills.propTypes = {
  missingSkills: PropTypes.arrayOf(PropTypes.string),
  userId: PropTypes.string,
  userSkills: PropTypes.arrayOf(PropTypes.string),
};
