import axios from 'axios';
import * as Sentry from '@sentry/react';

export function fetcher(args) {
  // eslint-disable-next-line no-unused-vars
  const [url, enqueueSnackbar, config] = Array.isArray(args) ? args : [args];

  const axiosInstance = axios.create({
    baseURL: import.meta.env.VITE_HOST_API,
    timeout: 10000,
    withCredentials: true,
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status >= 300) {
        console.error('API Request failed', {
          endpoint: `${error.config.baseURL}${error.config.url}`,
          response: `${error.response.status} ${error.response.statusText}`,
          responseData: JSON.stringify(error.response.data),
        });

        Sentry.setContext('response', {
          endpoint: `${error.config.baseURL}${error.config.url}`,
          response: `${error.response.status} ${error.response.statusText}`,
          responseData: JSON.stringify(error.response.data),
        });
      }

      if (error.response.status === 404) {
        return;
      }

      console.error(error.response);
      throw error;
    }
  );

  return axiosInstance.get(url, { ...config }).then((res) => res.data);
}
