import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

// SETUP COLORS

export const grey = {
  0: '#FFFFFF',
  25: '#FAFAFA',
  50: '#F7F7F7',
  100: '#F2F2F2',
  200: '#E6E6E6',
  300: '#D4D4D4',
  400: '#BABABA',
  500: '#979797',
  600: '#6A6A6A',
  700: '#414141',
  800: '#262626',
  900: '#191919',
  950: '#151515',
};

export const primary = {
  25: '#EEFDF4',
  50: '#E5FCEE',
  100: '#A6FACB',
  200: '#7BFAB9',
  300: '#42EA9F',
  400: '#27D48C',
  500: '#1FB979',
  600: '#199B65',
  700: '#127B4F',
  800: '#0A5A39',
  900: '#043A23',
  950: '#021A0E',
  lighter: '#A6FACB',
  light: '#42EA9F',
  main: '#1FB979',
  dark: '#127B4F',
  darker: '#043A23',
  contrastText: '#FFFFFF',
};

export const secondary = {
  25: '#F9F7FC',
  50: '#F5F2FA',
  100: '#EFE6FA',
  200: '#E5D5FA',
  300: '#D9BDFC',
  400: '#CDA6FA',
  500: '#BA7AFB',
  600: '#A845F8',
  700: '#8A19D6',
  800: '#650D9F',
  900: '#410369',
  950: '#1F0335',
  lighter: '#E5D5FA',
  light: '#CDA6FA',
  main: '#A845F8',
  dark: '#650D9F',
  darker: '#1F0335',
  contrastText: '#FFFFFF',
};

export const info = {
  // -> cyan
  25: '#F5FEFF',
  50: '#ECFDFF',
  100: '#CFF9FE',
  200: '#A5F0FC',
  300: '#67E3F9',
  400: '#22CCEE ',
  500: '#06AED4',
  600: '#088AB2',
  700: '#0E7090',
  800: '#155B75',
  900: '#164C63',
  950: '#0D2D3A',
  lighter: '#CFF9FE',
  light: '#67E3F9',
  main: '#06AED4',
  dark: '#0E7090',
  darker: '#164C63',
  contrastText: '#FFFFFF',
};

export const success = {
  25: '#EEFDF4',
  50: '#E5FCEE',
  100: '#A6FACB',
  200: '#7BFAB9',
  300: '#42EA9F',
  400: '#27D48C',
  500: '#1FB979',
  600: '#199B65',
  700: '#127B4F',
  800: '#0A5A39',
  900: '#043A23',
  950: '#021A0E',
  lighter: '#A6FACB',
  light: '#42EA9F',
  main: '#1FB979',
  dark: '#127B4F',
  darker: '#043A23',
  contrastText: '#ffffff',
};

export const warning = {
  25: '#FDF7F1',
  50: '#FCF4EA',
  100: '#FAE8D2',
  200: '#FAD4A6',
  300: '#FBBB68',
  400: '#F1A11C',
  500: '#D38C12',
  600: '#B1750E',
  700: '#8D5C09',
  800: '#684303',
  900: '#442A02',
  950: '#201201',
  lighter: '#FAE8D2',
  light: '#FBBB68',
  main: '#D38C12',
  dark: '#8D5C09',
  darker: '#442A02',
  contrastText: grey[800],
};

export const error = {
  25: '#FCF7F7',
  50: '#FAF1F1',
  100: '#F8E4E4',
  200: '#F8D0D1',
  300: '#FAA6A9',
  400: '#FC9297',
  500: '#FB6373',
  600: '#EE214E',
  700: '#BF193D',
  800: '#8E102C',
  900: '#5D0619',
  950: '#2E0309',
  lighter: '#F8E4E4',
  light: '#FAA6A9',
  main: '#FB6373',
  dark: '#BF193D',
  darker: '#5D0619',
  contrastText: '#FFFFFF',
};

export const common = {
  black: '#000000',
  white: '#FFFFFF',
};

export const action = {
  hover: alpha(grey[500], 0.08),
  selected: alpha(grey[500], 0.16),
  disabled: alpha(grey[500], 0.8),
  disabledBackground: alpha(grey[500], 0.24),
  focus: alpha(grey[500], 0.24),
  hoverOpacity: 0.08,
  disabledOpacity: 0.48,
};

const base = {
  primary,
  secondary,
  info,
  success,
  warning,
  error,
  grey,
  common,
  divider: alpha(grey[500], 0.2),
  action,
};

// ----------------------------------------------------------------------

export function palette(mode) {
  const light = {
    ...base,
    mode: 'light',
    text: {
      primary: grey[900],
      secondary: grey[700],
      ternary: grey[600],
      disabled: grey[500],
      quarternary: grey[500],
    },
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF',
      neutral: '#FFFFFF',
    },
    action: {
      ...base.action,
      active: grey[600],
    },
  };

  const dark = {
    ...base,
    mode: 'dark',
    text: {
      primary: '#FFFFFF',
      secondary: grey[500],
      disabled: grey[600],
    },
    background: {
      paper: grey[800],
      default: grey[900],
      neutral: alpha(grey[500], 0.12),
    },
    action: {
      ...base.action,
      active: grey[500],
    },
  };

  return mode === 'light' ? light : dark;
}
