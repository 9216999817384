import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Box, Stack, Tooltip, useTheme, Skeleton, Typography } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import { fDate } from 'src/utils/format-time';
import { fCurrency } from 'src/utils/format-number';
import workplaceTypeLabel from 'src/utils/workplace';
import workloadTypeLabel from 'src/utils/workload-types';
import contractTypeLabel, { CONTRACT_TYPES } from 'src/utils/contract-types';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

const parseStartDate = (value) => {
  if (!value) {
    return value;
  }

  try {
    return fDate(value);
  } catch (e) {
    return value;
  }
};

function isNumeric(n) {
  return !Number.isNaN(parseFloat(n)) && Number.isFinite(n);
}

export const factCols = (details, distanceToUser, isSkeleton) =>
  details
    ? [
        {
          title: 'Start date',
          key: 'startDate',
          icon: 'solar:calendar-date-bold',
          value: details.startDate ? parseStartDate(details.startDate) : 'asap',
        },
        {
          title: 'Hourly Rate',
          key: 'hourlyRate',
          icon: 'ic:sharp-euro',
          // eslint-disable-next-line no-nested-ternary
          value: details.hourlyRate
            ? isNumeric(details.hourlyRate)
              ? fCurrency(details.hourlyRate).replace('€', '')
              : details.hourlyRate
            : 'Open budget',
        },
        {
          title: 'Type',
          key: 'contractType',
          icon: 'solar:user-hands-bold',
          value: CONTRACT_TYPES.map((v) => v.value).includes(details?.contractType)
            ? contractTypeLabel(details.contractType)
            : contractTypeLabel('other'),
        },
        {
          title: 'Workload',
          key: 'workload',
          icon: 'solar:hourglass-line-bold',
          value: details?.commitmentLevel
            ? workloadTypeLabel(details?.commitmentLevel)
            : workloadTypeLabel('other'),
        },
        {
          title: 'Workplace',
          key: 'workplace',
          icon: 'solar:buildings-bold',
          value: workplaceTypeLabel(details?.workplace),
        },
        {
          title: 'Location',
          key: 'location',
          icon: 'solar:point-on-map-bold',
          value: distanceToUser
            ? `${details?.location} (approx. ${distanceToUser} km)`
            : details?.location,
        },
      ]
        .filter((col) => col.value)
        .map((v) => ({
          ...v,
          value: isSkeleton ? <Skeleton variant="text" width={60} /> : v.value,
        }))
    : [];

// ----------------------------------------------------------------------

export default function BoardListItemFactsList({ isSkeleton, details, distanceToUser, sx }) {
  const facts = useMemo(
    () => factCols(details, distanceToUser, isSkeleton),
    [details, distanceToUser, isSkeleton]
  );
  const theme = useTheme();
  const isSmDown = useResponsive('down', 'sm');

  return (
    <Stack
      spacing={{ xs: 2, sm: 3 }}
      direction="row"
      flexWrap={isSmDown ? 'wrap' : 'nowrap'}
      sx={{
        rowGap: { xs: 1, sm: 3 },
        overflowX: 'scroll',
        '::-webkit-scrollbar': { display: 'none' },
        ...sx,
      }}
    >
      {facts.map((fact) => (
        <Box key={fact.key}>
          <Tooltip title={fact.title}>
            <Stack direction="row" spacing={0.5}>
              <Iconify icon={fact.icon} width={16} color={theme.palette.grey[600]} />
              <Typography
                variant="caption"
                sx={{ color: theme.palette.grey[600], whiteSpace: 'nowrap' }}
              >
                {fact.value}
              </Typography>
            </Stack>
          </Tooltip>
        </Box>
      ))}
    </Stack>
  );
}

BoardListItemFactsList.propTypes = {
  details: PropTypes.object.isRequired,
  distanceToUser: PropTypes.number,
  sx: PropTypes.object,
  isSkeleton: PropTypes.bool,
};
