import PropTypes from 'prop-types';
import React, { useMemo, useState, useEffect, useContext, useCallback, createContext } from 'react';

const FeatureFlagsContext = createContext();
const STORAGE_KEY = 'FEATURE_FLAGS';

export const FeatureFlagsProvider = ({ children }) => {
  // Read initial state from localStorage if available
  const [featureFlags, setFeatureFlags] = useState(() => {
    try {
      const storedFlags = localStorage.getItem(STORAGE_KEY);
      return storedFlags ? JSON.parse(storedFlags) : [];
    } catch (error) {
      console.error('Error reading feature flags from localStorage', error);
      return [];
    }
  });

  // Synchronize state changes to localStorage
  useEffect(() => {
    try {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(featureFlags));
    } catch (error) {
      console.error('Error saving feature flags to localStorage', error);
    }
  }, [featureFlags]);

  // On mount: read query parameters and add feature flags if present
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const flagsFromUrl = params.getAll('feature');
    if (flagsFromUrl.length > 0) {
      setFeatureFlags((prev) => Array.from(new Set([...prev, ...flagsFromUrl])));
    }
  }, []);

  const addFlag = useCallback((flag) => {
    setFeatureFlags((prev) => Array.from(new Set([...prev, flag])));
  }, []);

  const removeFlag = useCallback((flag) => {
    setFeatureFlags((prev) => prev.filter((f) => f !== flag));
  }, []);

  const clearFlags = useCallback(() => {
    setFeatureFlags([]);
  }, []);

  // Convenience function to check if a flag is active
  const hasFlag = useCallback((flag) => featureFlags.includes(flag), [featureFlags]);

  const contextValue = useMemo(
    () => ({
      featureFlags,
      addFlag,
      removeFlag,
      clearFlags,
      hasFlag,
    }),
    [featureFlags, addFlag, removeFlag, clearFlags, hasFlag]
  );

  return (
    <FeatureFlagsContext.Provider value={contextValue}>{children}</FeatureFlagsContext.Provider>
  );
};

FeatureFlagsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useFeatureFlags = () => useContext(FeatureFlagsContext);
