import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import { HEADER_SIZE_MOBILE, HEADER_SIZE_DESKTOP } from 'src/layouts/root/vars';

import EnvironmentBanner from 'src/components/environment-banner';
import BackgroundBlobs from 'src/components/background-blobs/background-blobs';

import FooterSitemapSection from 'src/sections/home/sections/footer-sitemap';

import Footer from './footer';
import Header from './header';

// ----------------------------------------------------------------------

export default function RootLayout({ children }) {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        minHeight: 1,
        pt: { xs: `${HEADER_SIZE_MOBILE}px`, md: `${HEADER_SIZE_DESKTOP}px` },
      }}
    >
      <BackgroundBlobs />
      <EnvironmentBanner />
      <Header />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          position: 'relative',
        }}
      >
        {children}
      </Box>

      <FooterSitemapSection />
      <Footer />
    </Box>
  );
}

RootLayout.propTypes = {
  children: PropTypes.node,
};
